import React from 'react';
import { DonutChart } from '@tremor/react';
import { motion } from 'framer-motion';
import { Users, ArrowRight } from 'lucide-react';

export function LeadsBySource() {
  const data = [
    { source: 'Website', value: 35, color: 'blue' },
    { source: 'Referral', value: 25, color: 'emerald' },
    { source: 'Social Media', value: 20, color: 'violet' },
    { source: 'Direct', value: 15, color: 'amber' },
    { source: 'Other', value: 5, color: 'slate' }
  ];

  const totalLeads = data.reduce((sum, item) => sum + item.value, 0);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: 0.2 }}
      className="bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 rounded-xl shadow-sm p-6"
    >
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-2">
          <Users className="h-5 w-5 text-blue-600" />
          <h3 className="text-lg font-semibold text-gray-900">Leads by Source</h3>
        </div>
        <button className="text-sm text-blue-600 hover:text-blue-700 flex items-center">
          View Details
          <ArrowRight className="ml-1 h-4 w-4" />
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="relative">
          <DonutChart
            data={data}
            category="value"
            index="source"
            valueFormatter={(value) => `${value}%`}
            colors={["blue", "emerald", "violet", "amber", "slate"]}
            className="h-60"
            showAnimation={true}
            animationDuration={1000}
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="text-center">
              <div className="text-3xl font-bold text-gray-900">{totalLeads}</div>
              <div className="text-sm text-gray-500">Total Leads</div>
            </div>
          </div>
        </div>

        <div className="space-y-4">
          {data.map((item, index) => (
            <motion.div
              key={item.source}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className="flex items-center justify-between p-3 rounded-lg bg-white shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="flex items-center gap-3">
                <div className={`w-3 h-3 rounded-full bg-${item.color}-500`} />
                <span className="font-medium text-gray-700">{item.source}</span>
              </div>
              <div className="flex items-center gap-4">
                <span className="text-lg font-semibold text-gray-900">{item.value}%</span>
                <div className="w-24 h-2 bg-gray-100 rounded-full overflow-hidden">
                  <div 
                    className={`h-full bg-${item.color}-500 rounded-full transition-all duration-1000`}
                    style={{ width: `${item.value}%` }}
                  />
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}