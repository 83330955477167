import React, { useState } from 'react';
import { Star, ThumbsUp, MessageSquare, Mail, Send, Loader2 } from 'lucide-react';
import { generateReviewLink } from '../../utils/reviewTracking';
import { sendSMS } from '../../utils/twilio';
import type { Customer } from '../../types/customer';

interface ReviewManagerProps {
  customer?: Customer;
}

export function ReviewManager({ customer }: ReviewManagerProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleSendReviewRequest = async () => {
    if (!customer) return;
    
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const reviewLink = await generateReviewLink(customer.id);
      const message = `Thank you for choosing our dealership! We value your feedback. Please take a moment to share your experience: ${reviewLink}`;

      await sendSMS({
        to: customer.phone,
        message: message
      });

      setSuccessMessage('Review request sent successfully!');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to send review request');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h2 className="text-xl font-semibold text-gray-900">Review Management</h2>
          <p className="mt-1 text-sm text-gray-500">
            Monitor and manage customer reviews
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600">Average Rating</p>
              <p className="mt-1 text-3xl font-semibold text-gray-900">4.8</p>
            </div>
            <div className="h-12 w-12 rounded-full bg-yellow-50 flex items-center justify-center">
              <Star className="h-6 w-6 text-yellow-600 fill-current" />
            </div>
          </div>
          <div className="mt-4">
            <div className="flex items-center text-sm">
              <span className="text-gray-500">Based on 128 reviews</span>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600">Review Requests</p>
              <p className="mt-1 text-3xl font-semibold text-gray-900">245</p>
            </div>
            <div className="h-12 w-12 rounded-full bg-blue-50 flex items-center justify-center">
              <Mail className="h-6 w-6 text-blue-600" />
            </div>
          </div>
          <div className="mt-4">
            <div className="flex items-center text-sm">
              <span className="text-gray-500">52% response rate</span>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600">Recent Reviews</p>
              <p className="mt-1 text-3xl font-semibold text-gray-900">12</p>
            </div>
            <div className="h-12 w-12 rounded-full bg-green-50 flex items-center justify-center">
              <MessageSquare className="h-6 w-6 text-green-600" />
            </div>
          </div>
          <div className="mt-4">
            <div className="flex items-center text-sm">
              <span className="text-gray-500">Last 7 days</span>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600">Positive Sentiment</p>
              <p className="mt-1 text-3xl font-semibold text-gray-900">92%</p>
            </div>
            <div className="h-12 w-12 rounded-full bg-purple-50 flex items-center justify-center">
              <ThumbsUp className="h-6 w-6 text-purple-600" />
            </div>
          </div>
          <div className="mt-4">
            <div className="flex items-center text-sm">
              <span className="text-gray-500">Based on sentiment analysis</span>
            </div>
          </div>
        </div>
      </div>

      {customer && (
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div>
              <h3 className="text-lg font-medium text-gray-900">
                Send Review Request
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Customer: {customer.firstName} {customer.lastName}
              </p>
            </div>
            <div className="mt-4 sm:mt-0">
              <button
                onClick={handleSendReviewRequest}
                disabled={loading}
                className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 disabled:opacity-50"
              >
                {loading ? (
                  <>
                    <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                    Sending...
                  </>
                ) : (
                  <>
                    <Send className="-ml-1 mr-2 h-5 w-5" />
                    Send Request
                  </>
                )}
              </button>
            </div>
          </div>

          {error && (
            <div className="mt-4 rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Error</h3>
                  <div className="mt-2 text-sm text-red-700">
                    <p>{error}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {successMessage && (
            <div className="mt-4 rounded-md bg-green-50 p-4">
              <div className="flex">
                <div className="ml-3">
                  <p className="text-sm font-medium text-green-800">
                    {successMessage}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}