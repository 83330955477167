import React, { useState } from 'react';
import { Calendar, Clock, Users, Wand2, Loader2 } from 'lucide-react';
import { useAI } from '../components/ai/AIContext';

interface FollowUpPlan {
  timing: string;
  message: string;
  channel: 'email' | 'sms' | 'call';
  priority: 'high' | 'medium' | 'low';
}

export function FollowUpPlanner() {
  const { generateResponse, isLoading } = useAI();
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    lastInteraction: '',
    interactionType: '',
    interests: '',
    stage: 'new',
    notes: ''
  });
  const [plan, setPlan] = useState<FollowUpPlan[] | null>(null);

  const handleGeneratePlan = async () => {
    const prompt = `Create a follow-up plan for:
      Customer: ${customerInfo.name}
      Last Interaction: ${customerInfo.lastInteraction}
      Interaction Type: ${customerInfo.interactionType}
      Interests: ${customerInfo.interests}
      Stage: ${customerInfo.stage}
      Notes: ${customerInfo.notes}

      Provide a detailed follow-up plan with:
      1. Optimal timing for each touchpoint
      2. Suggested message content
      3. Best communication channel
      4. Priority level
      Format as: Timing | Message | Channel | Priority`;

    const response = await generateResponse(prompt);
    if (response) {
      // Parse the response into structured data
      const plans = response.split('\n')
        .filter(line => line.trim())
        .map(line => {
          const [timing, message, channel, priority] = line.split('|').map(s => s.trim());
          return { timing, message, channel, priority } as FollowUpPlan;
        });
      setPlan(plans);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center gap-2 mb-6">
          <Calendar className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">Follow-up Planner</h2>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">Customer Name</label>
              <input
                type="text"
                value={customerInfo.name}
                onChange={(e) => setCustomerInfo({ ...customerInfo, name: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="e.g. John Smith"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Last Interaction</label>
              <input
                type="text"
                value={customerInfo.lastInteraction}
                onChange={(e) => setCustomerInfo({ ...customerInfo, lastInteraction: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="e.g. Test drive yesterday"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Interaction Type</label>
              <select
                value={customerInfo.interactionType}
                onChange={(e) => setCustomerInfo({ ...customerInfo, interactionType: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">Select type</option>
                <option value="test_drive">Test Drive</option>
                <option value="showroom_visit">Showroom Visit</option>
                <option value="phone_call">Phone Call</option>
                <option value="email">Email Exchange</option>
                <option value="web_inquiry">Web Inquiry</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Customer Stage</label>
              <select
                value={customerInfo.stage}
                onChange={(e) => setCustomerInfo({ ...customerInfo, stage: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="new">New Lead</option>
                <option value="contacted">Contacted</option>
                <option value="qualified">Qualified</option>
                <option value="proposal">Proposal</option>
                <option value="negotiation">Negotiation</option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Customer Interests</label>
            <textarea
              value={customerInfo.interests}
              onChange={(e) => setCustomerInfo({ ...customerInfo, interests: e.target.value })}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="e.g. Interested in SUVs, needs 7 seats, prefers hybrid"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Additional Notes</label>
            <textarea
              value={customerInfo.notes}
              onChange={(e) => setCustomerInfo({ ...customerInfo, notes: e.target.value })}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="Any additional context or special considerations"
            />
          </div>

          <button
            onClick={handleGeneratePlan}
            disabled={isLoading}
            className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                Generating Plan...
              </>
            ) : (
              <>
                <Wand2 className="-ml-1 mr-2 h-5 w-5" />
                Generate Follow-up Plan
              </>
            )}
          </button>
        </div>

        {plan && (
          <div className="mt-8">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Follow-up Plan</h3>
            <div className="space-y-4">
              {plan.map((step, index) => (
                <div
                  key={index}
                  className="bg-gray-50 rounded-lg p-4 border border-gray-200"
                >
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center">
                      <Clock className="h-5 w-5 text-gray-400 mr-2" />
                      <span className="font-medium text-gray-900">{step.timing}</span>
                    </div>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      step.priority === 'high' ? 'bg-red-100 text-red-800' :
                      step.priority === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-green-100 text-green-800'
                    }`}>
                      {step.priority} priority
                    </span>
                  </div>
                  <p className="text-gray-600 mb-2">{step.message}</p>
                  <div className="flex items-center text-sm text-gray-500">
                    <Users className="h-4 w-4 mr-1" />
                    Via {step.channel}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}