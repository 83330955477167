import React, { useState } from 'react';
import { Lightbulb, Wand2, Loader2, Beaker, Zap, Brain } from 'lucide-react';
import { useAI } from '../components/ai/AIContext';

interface Experiment {
  id: string;
  name: string;
  description: string;
  status: 'active' | 'completed' | 'failed';
  results?: string;
}

export function InnovationLab() {
  const { generateResponse, isLoading } = useAI();
  const [experiments, setExperiments] = useState<Experiment[]>([]);
  const [newExperiment, setNewExperiment] = useState({
    name: '',
    description: '',
    hypothesis: '',
    approach: ''
  });
  const [selectedExperiment, setSelectedExperiment] = useState<Experiment | null>(null);

  const handleCreateExperiment = async () => {
    const prompt = `Design an AI experiment for:
      Name: ${newExperiment.name}
      Description: ${newExperiment.description}
      Hypothesis: ${newExperiment.hypothesis}
      Approach: ${newExperiment.approach}

      Provide:
      1. Experimental design
      2. Success metrics
      3. Risk factors
      4. Expected outcomes
      5. Implementation steps`;

    const response = await generateResponse(prompt);
    if (response) {
      const experiment: Experiment = {
        id: Date.now().toString(),
        name: newExperiment.name,
        description: newExperiment.description,
        status: 'active',
        results: response
      };
      setExperiments([experiment, ...experiments]);
      setNewExperiment({
        name: '',
        description: '',
        hypothesis: '',
        approach: ''
      });
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center gap-2 mb-6">
          <Lightbulb className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">Innovation Lab</h2>
        </div>

        <div className="space-y-6">
          <div className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg p-6">
            <h3 className="flex items-center text-lg font-medium text-gray-900 mb-2">
              <Beaker className="h-5 w-5 mr-2 text-blue-500" />
              New AI Experiment
            </h3>
            <p className="text-sm text-gray-600 mb-4">
              Design and test new AI-powered features and capabilities
            </p>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Experiment Name</label>
                <input
                  type="text"
                  value={newExperiment.name}
                  onChange={(e) => setNewExperiment({ ...newExperiment, name: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="e.g. Advanced Lead Scoring Algorithm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  value={newExperiment.description}
                  onChange={(e) => setNewExperiment({ ...newExperiment, description: e.target.value })}
                  rows={2}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Describe the experiment and its goals"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Hypothesis</label>
                <textarea
                  value={newExperiment.hypothesis}
                  onChange={(e) => setNewExperiment({ ...newExperiment, hypothesis: e.target.value })}
                  rows={2}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="What do you expect to achieve?"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Approach</label>
                <textarea
                  value={newExperiment.approach}
                  onChange={(e) => setNewExperiment({ ...newExperiment, approach: e.target.value })}
                  rows={2}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="How will you test your hypothesis?"
                />
              </div>

              <button
                onClick={handleCreateExperiment}
                disabled={isLoading}
                className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {isLoading ? (
                  <>
                    <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                    Creating Experiment...
                  </>
                ) : (
                  <>
                    <Wand2 className="-ml-1 mr-2 h-5 w-5" />
                    Create Experiment
                  </>
                )}
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            {experiments.map((experiment) => (
              <div
                key={experiment.id}
                className="bg-white rounded-lg border border-gray-200 p-6 hover:shadow-md transition-shadow duration-200"
              >
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-medium text-gray-900">{experiment.name}</h3>
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    experiment.status === 'active' ? 'bg-green-100 text-green-800' :
                    experiment.status === 'completed' ? 'bg-blue-100 text-blue-800' :
                    'bg-red-100 text-red-800'
                  }`}>
                    {experiment.status}
                  </span>
                </div>

                <p className="text-sm text-gray-500 mb-4">{experiment.description}</p>

                <button
                  onClick={() => setSelectedExperiment(experiment)}
                  className="inline-flex items-center text-sm text-blue-600 hover:text-blue-500"
                >
                  <Brain className="h-4 w-4 mr-1.5" />
                  View Results
                </button>
              </div>
            ))}
          </div>

          {selectedExperiment && (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="absolute right-0 top-0 pr-4 pt-4">
                      <button
                        onClick={() => setSelectedExperiment(null)}
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      >
                        <span className="sr-only">Close</span>
                        <Zap className="h-6 w-6" />
                      </button>
                    </div>

                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <h3 className="text-lg font-semibold leading-6 text-gray-900">
                          Experiment Results
                        </h3>
                        <div className="mt-4">
                          <div className="prose prose-blue max-w-none">
                            {selectedExperiment.results?.split('\n').map((line, index) => (
                              <p key={index} className="text-gray-600">{line}</p>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}