import { Customer } from '../types/customer';
import { v4 as uuidv4 } from 'uuid';

export const sampleCustomers: Customer[] = [
  {
    id: uuidv4(),
    firstName: 'Michael',
    lastName: 'Chen',
    email: 'michael.chen@email.com',
    phone: '(902) 555-0123',
    address: '123 Spring Garden Road, Halifax, NS B3J 2K9',
    purchaseDate: '2024-01-15',
    birthday: '1985-03-22',
    status: 'active',
    preferred_contact: 'email',
    vehicle: {
      make: 'BMW',
      model: 'M5',
      year: 2024,
      vin: 'WBS83CH07PCK12345'
    },
    salesRepId: '1',
    notes: 'Performance enthusiast, interested in M series vehicles',
    lastContact: new Date('2024-03-15').toISOString()
  },
  {
    id: uuidv4(),
    firstName: 'Sarah',
    lastName: 'Williams',
    email: 'sarah.w@email.com',
    phone: '(902) 555-0456',
    address: '456 Quinpool Road, Halifax, NS B3K 4M2',
    purchaseDate: '2023-11-20',
    birthday: '1990-06-15',
    status: 'active',
    preferred_contact: 'phone',
    vehicle: {
      make: 'Audi',
      model: 'Q7',
      year: 2023,
      vin: 'WAU2GAFC7KN123456'
    },
    salesRepId: '2',
    notes: 'Family-oriented customer, values safety features',
    lastContact: new Date('2024-03-10').toISOString()
  },
  {
    id: uuidv4(),
    firstName: 'James',
    lastName: 'Thompson',
    email: 'james.t@email.com',
    phone: '(902) 555-0789',
    address: '789 Barrington Street, Halifax, NS B3H 2A1',
    purchaseDate: '2024-02-28',
    birthday: '1978-09-10',
    status: 'active',
    preferred_contact: 'email',
    anniversary: '2024-02-28',
    vehicle: {
      make: 'Mercedes-Benz',
      model: 'S-Class',
      year: 2024,
      vin: 'WDDUG8FB5MA123456'
    },
    salesRepId: '1',
    notes: 'Luxury vehicle enthusiast, particular about service quality',
    lastContact: new Date('2024-03-14').toISOString()
  },
  {
    id: uuidv4(),
    firstName: 'Emily',
    lastName: 'Rodriguez',
    email: 'emily.r@email.com',
    phone: '(902) 555-0321',
    address: '321 Robie Street, Halifax, NS B3K 5R2',
    purchaseDate: '2024-03-01',
    birthday: '1992-12-03',
    status: 'active',
    preferred_contact: 'sms',
    vehicle: {
      make: 'Tesla',
      model: 'Model Y',
      year: 2024,
      vin: '5YJYGDEF9PF123456'
    },
    salesRepId: '2',
    notes: 'Environmental conscious, interested in electric vehicles',
    lastContact: new Date('2024-03-16').toISOString()
  },
  {
    id: uuidv4(),
    firstName: 'Robert',
    lastName: 'MacKenzie',
    email: 'robert.m@email.com',
    phone: '(902) 555-0654',
    address: '654 Dresden Row, Halifax, NS B3J 2J9',
    purchaseDate: '2023-12-10',
    birthday: '1983-07-28',
    status: 'active',
    preferred_contact: 'phone',
    vehicle: {
      make: 'Porsche',
      model: 'Cayenne',
      year: 2023,
      vin: 'WP1AA2A95PL123456'
    },
    salesRepId: '1',
    notes: 'Sports car enthusiast, interested in performance SUVs',
    lastContact: new Date('2024-03-12').toISOString()
  },
  {
    id: uuidv4(),
    firstName: 'Jennifer',
    lastName: 'Lee',
    email: 'jennifer.l@email.com',
    phone: '(902) 555-0987',
    address: '987 South Park Street, Halifax, NS B3J 2L9',
    purchaseDate: '2024-01-05',
    birthday: '1988-04-15',
    status: 'active',
    preferred_contact: 'email',
    vehicle: {
      make: 'Range Rover',
      model: 'Sport',
      year: 2024,
      vin: 'SALW2EF79PA123456'
    },
    salesRepId: '2',
    notes: 'Prefers luxury SUVs, interested in off-road capabilities',
    lastContact: new Date('2024-03-13').toISOString()
  },
  {
    id: uuidv4(),
    firstName: 'William',
    lastName: 'Parker',
    email: 'william.p@email.com',
    phone: '(902) 555-0234',
    address: '234 Queen Street, Halifax, NS B3J 2H7',
    purchaseDate: '2024-02-15',
    birthday: '1975-11-30',
    status: 'active',
    preferred_contact: 'phone',
    vehicle: {
      make: 'Lexus',
      model: 'LS',
      year: 2024,
      vin: 'JTHC81F27P5123456'
    },
    salesRepId: '1',
    notes: 'Values reliability and comfort, regular service customer',
    lastContact: new Date('2024-03-11').toISOString()
  },
  {
    id: uuidv4(),
    firstName: 'Maria',
    lastName: 'Garcia',
    email: 'mgarcia@email.com',
    phone: '(902) 555-4680',
    address: '567 Brunswick Street, Halifax, NS B3K 2Y9',
    purchaseDate: '2024-03-01',
    birthday: '1988-07-15',
    status: 'active',
    preferred_contact: 'email',
    vehicle: {
      make: 'Genesis',
      model: 'G90',
      year: 2024,
      vin: 'KMTNA4KC2PU234567'
    },
    salesRepId: '2',
    notes: 'First-time luxury car buyer, interested in technology features',
    lastContact: new Date('2024-03-15').toISOString()
  }
];

export const generateSampleCustomers = () => sampleCustomers;