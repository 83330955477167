import React, { useState } from 'react';
import { Mail, Wand2, Loader2, Video, Calendar, Users, BookTemplate as Template, Clock } from 'lucide-react';
import type { Customer } from '../../types/customer';
import { VideoMessageComposer } from '../video/VideoMessageComposer';
import { useTemplates } from '../../hooks/useTemplates';
import { AIMessageGenerator } from './AIMessageGenerator';

interface QuickReply {
  text: string;
  category: string;
}

const QUICK_REPLIES: QuickReply[] = [
  { text: "I'll check and get back to you shortly", category: 'general' },
  { text: "Yes, that works for me", category: 'confirmation' },
  { text: "Would you like to schedule a call?", category: 'follow_up' },
  { text: "Thank you for your message", category: 'general' },
  { text: "Great! When would you like to come in?", category: 'appointment' },
  { text: "I can help you with that", category: 'service' }
];
interface SMSComposerProps {
  onClose: () => void;
  onSent: () => void;
}

export function SMSComposer({ onClose, onSent }: SMSComposerProps) {
  const [content, setContent] = useState('');
  const [scheduledDate, setScheduledDate] = useState<string>('');
  const [recipients, setRecipients] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [showVideoComposer, setShowVideoComposer] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { templates, selectedTemplate, selectedTemplateData, setSelectedTemplate } = useTemplates('sms');
  const [showBulkOptions, setShowBulkOptions] = useState(false);
  const [showQuickReplies, setShowQuickReplies] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('all');

  const generateMessage = async () => {
    // Existing generateMessage implementation
  };

  const handleMessageGenerated = (generatedMessage: string) => {
    // Existing handleMessageGenerated implementation
  };

  return (
    <form>
      <div className="mb-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <select
              value={selectedTemplate}
              onChange={(e) => setSelectedTemplate(e.target.value)}
              className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="">Select Template</option>
              {templates.map(template => (
                <option key={template.id} value={template.id}>{template.name}</option>
              ))}
            </select>
            <button
              type="button"
              onClick={() => setShowQuickReplies(!showQuickReplies)}
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <MessageSquare className="h-4 w-4 mr-1.5" />
              Quick Replies
            </button>
            <button
              type="button"
              onClick={() => setShowBulkOptions(!showBulkOptions)}
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <Users className="h-4 w-4 mr-1.5" />
              Bulk Send
            </button>
          </div>
        </div>
      </div>

      {showQuickReplies && (
        <div className="mb-4 p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-sm font-medium text-gray-900">Quick Replies</h3>
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="text-sm rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="all">All Categories</option>
              <option value="general">General</option>
              <option value="confirmation">Confirmation</option>
              <option value="follow_up">Follow-up</option>
              <option value="appointment">Appointment</option>
              <option value="service">Service</option>
            </select>
          </div>
          <div className="grid grid-cols-2 gap-2">
            {QUICK_REPLIES
              .filter(reply => selectedCategory === 'all' || reply.category === selectedCategory)
              .map((reply, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => setContent(reply.text)}
                  className="text-left px-3 py-2 text-sm bg-white rounded-md hover:bg-gray-100 transition-colors"
                >
                  {reply.text}
                </button>
              ))
            }
          </div>
        </div>
      )}

      <div className="mb-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <select
              value={selectedTemplate}
              onChange={(e) => setSelectedTemplate(e.target.value)}
              className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="">Select Template</option>
              {templates.map(template => (
                <option key={template.id} value={template.id}>{template.name}</option>
              ))}
            </select>
            <button
              type="button"
              onClick={() => setShowBulkOptions(!showBulkOptions)}
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <Users className="h-4 w-4 mr-1.5" />
              Bulk Send
            </button>
          </div>
        </div>
      </div>

      {showBulkOptions && (
        <div className="mb-4 p-4 bg-gray-50 rounded-lg">
          <h3 className="text-sm font-medium text-gray-900 mb-2">Bulk Recipients</h3>
          <div className="space-y-2">
            <textarea
              value={recipients.join('\n')}
              onChange={(e) => setRecipients(e.target.value.split('\n').filter(r => r.trim()))}
              rows={3}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              placeholder="Enter phone numbers (one per line)"
            />
            <p className="text-sm text-gray-500">{recipients.length} recipients selected</p>
          </div>
        </div>
      )}

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Schedule Message
        </label>
        <div className="mt-1 flex items-center space-x-2">
          <input
            type="datetime-local"
            value={scheduledDate}
            onChange={(e) => setScheduledDate(e.target.value)}
            className="block rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
          {scheduledDate && (
            <button
              type="button"
              onClick={() => setScheduledDate('')}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="h-5 w-5" />
            </button>
          )}
        </div>
      </div>

      <div className="flex items-center justify-between">
        <AIMessageGenerator
          messageType="sms"
          onMessageGenerated={handleMessageGenerated}
          customerName="Customer"
        />
        <button
          onClick={() => setShowVideoComposer(true)}
          className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-100 px-3 py-2 text-sm font-semibold text-indigo-600 hover:bg-indigo-200"
        >
          <Video className="-ml-0.5 h-5 w-5" />
          Add Video Message
        </button>
      </div>

      <div>
        {/* Existing textarea and other form elements */}
      </div>

      <div className="flex justify-end space-x-3">
        {scheduledDate && (
          <div className="flex items-center text-sm text-gray-500 mr-4">
            <Clock className="h-4 w-4 mr-1.5" />
            Scheduled for: {new Date(scheduledDate).toLocaleString()}
          </div>
        )}
        <button
          type="button"
          onClick={onClose}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Cancel
        </button>
      </div>

      {showVideoComposer && (
        <VideoMessageComposer
          onClose={() => setShowVideoComposer(false)}
          onSend={async (videoUrl, message) => {
            setContent(prev => `${prev}\n\nVideo message: ${videoUrl}\n\n${message}`);
            setShowVideoComposer(false);
          }}
        />
      )}
    </form>
  );
}