import React, { useState } from 'react';
import { Brain, Loader2, MessageSquare, Book, CheckCircle2, Star, Video, Target, Users, Lightbulb, Zap } from 'lucide-react';
import { useAI } from '../ai/AIContext';
import { sampleTips } from '../../data/sampleTips';

interface Scenario {
  id: string;
  title: string;
  description: string;
  category: 'objection' | 'presentation' | 'closing' | 'follow-up';
  difficulty: 'beginner' | 'intermediate' | 'advanced';
}

interface Tool {
  id: string;
  name: string;
  description: string;
  icon: React.ComponentType<any>;
}

const SAMPLE_SCENARIOS: Scenario[] = [
  {
    id: '1',
    title: 'Price Objection',
    description: 'Customer says the price is too high compared to competitors',
    category: 'objection',
    difficulty: 'beginner'
  },
  {
    id: '2',
    title: 'Feature Presentation',
    description: 'Explaining advanced vehicle technology features',
    category: 'presentation',
    difficulty: 'intermediate'
  },
  {
    id: '3',
    title: 'Closing Techniques',
    description: 'Practice different approaches to closing a sale',
    category: 'closing',
    difficulty: 'advanced'
  }
];

const SALES_TOOLS: Tool[] = [
  {
    id: 'objection-handler',
    name: 'Objection Handler',
    description: 'Get AI-powered responses to common objections',
    icon: Target
  },
  {
    id: 'presentation-tips',
    name: 'Presentation Coach',
    description: 'Improve your vehicle presentation skills',
    icon: Video
  },
  {
    id: 'customer-profiler',
    name: 'Customer Profiler',
    description: 'Build detailed customer personas',
    icon: Users
  },
  {
    id: 'sales-scripts',
    name: 'Script Generator',
    description: 'Generate personalized sales scripts',
    icon: Book
  },
  {
    id: 'closing-coach',
    name: 'Closing Coach',
    description: 'Master different closing techniques',
    icon: Zap
  }
];

export function SalesCoach() {
  const { generateResponse, isLoading } = useAI();
  const [selectedScenario, setSelectedScenario] = useState<Scenario | null>(null);
  const [selectedTool, setSelectedTool] = useState<string | null>(null);
  const [userResponse, setUserResponse] = useState('');
  const [feedback, setFeedback] = useState<string | null>(null);
  const [score, setScore] = useState<number | null>(null);
  const [currentTip, setCurrentTip] = useState(() => 
    sampleTips[Math.floor(Math.random() * sampleTips.length)]
  );

  const refreshTip = () => {
    setCurrentTip(sampleTips[Math.floor(Math.random() * sampleTips.length)]);
  };

  const handleScenarioSelect = (scenario: Scenario) => {
    setSelectedScenario(scenario);
    setFeedback(null);
    setScore(null);
    setUserResponse('');
  };

  const handleSubmitResponse = async () => {
    if (!selectedScenario || !userResponse.trim()) return;

    const prompt = `As a sales coach, evaluate this response to the scenario: "${selectedScenario.title} - ${selectedScenario.description}"

Response: "${userResponse}"

Provide:
1. Score out of 100
2. What was done well
3. Areas for improvement
4. Suggested alternative approaches
5. Key phrases to remember`;

    const response = await generateResponse(prompt);
    if (response) {
      setFeedback(response);
      // Extract score from response (assuming it's in the first line)
      const scoreMatch = response.match(/\d+/);
      if (scoreMatch) {
        setScore(parseInt(scoreMatch[0]));
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      {/* Sales Tip Card */}
      <div className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg p-6 shadow-sm">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <Lightbulb className="h-6 w-6 text-blue-500 mr-2" />
            <h3 className="text-lg font-medium text-gray-900">Tip of the Moment</h3>
          </div>
          <button
            onClick={refreshTip}
            className="text-blue-600 hover:text-blue-700"
          >
            <Zap className="h-5 w-5" />
          </button>
        </div>
        <p className="text-gray-700">{currentTip}</p>
      </div>

      {/* Sales Tools Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {SALES_TOOLS.map((tool) => (
          <button
            key={tool.id}
            onClick={() => setSelectedTool(tool.id)}
            className={`p-4 rounded-lg border-2 transition-all ${
              selectedTool === tool.id
                ? 'border-blue-500 bg-blue-50'
                : 'border-gray-200 hover:border-blue-200 hover:bg-gray-50'
            }`}
          >
            <tool.icon className="h-8 w-8 mb-3 text-blue-500" />
            <h3 className="font-medium text-gray-900">{tool.name}</h3>
            <p className="mt-1 text-sm text-gray-500">{tool.description}</p>
          </button>
        ))}
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center gap-2 mb-6">
          <Brain className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">AI Sales Coach</h2>
        </div>

        {!selectedScenario ? (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {SAMPLE_SCENARIOS.map((scenario) => (
                <button
                  key={scenario.id}
                  onClick={() => handleScenarioSelect(scenario)}
                  className="flex flex-col p-4 rounded-lg border border-gray-200 hover:border-blue-300 hover:bg-blue-50 transition-colors text-left"
                >
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm font-medium text-gray-900">{scenario.title}</span>
                    <span className={`text-xs px-2 py-1 rounded-full ${
                      scenario.difficulty === 'beginner' ? 'bg-green-100 text-green-800' :
                      scenario.difficulty === 'intermediate' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-red-100 text-red-800'
                    }`}>
                      {scenario.difficulty}
                    </span>
                  </div>
                  <p className="text-sm text-gray-500">{scenario.description}</p>
                  <div className="mt-2">
                    <span className={`text-xs px-2 py-1 rounded-full ${
                      scenario.category === 'objection' ? 'bg-purple-100 text-purple-800' :
                      scenario.category === 'presentation' ? 'bg-blue-100 text-blue-800' :
                      scenario.category === 'closing' ? 'bg-green-100 text-green-800' :
                      'bg-gray-100 text-gray-800'
                    }`}>
                      {scenario.category}
                    </span>
                  </div>
                </button>
              ))}
            </div>
          </div>
        ) : (
          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-lg font-medium text-gray-900">{selectedScenario.title}</h3>
                <p className="mt-1 text-sm text-gray-500">{selectedScenario.description}</p>
              </div>
              <button
                onClick={() => setSelectedScenario(null)}
                className="text-sm text-blue-600 hover:text-blue-500"
              >
                Choose Different Scenario
              </button>
            </div>

            <div className="space-y-4">
              <label className="block text-sm font-medium text-gray-700">
                Your Response
              </label>
              <textarea
                value={userResponse}
                onChange={(e) => setUserResponse(e.target.value)}
                rows={4}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Enter your response to this scenario..."
              />
              <button
                onClick={handleSubmitResponse}
                disabled={isLoading || !userResponse.trim()}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {isLoading ? (
                  <>
                    <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                    Analyzing Response...
                  </>
                ) : (
                  <>
                    <MessageSquare className="-ml-1 mr-2 h-5 w-5" />
                    Submit Response
                  </>
                )}
              </button>
            </div>

            {feedback && (
              <div className="mt-6 bg-gray-50 rounded-lg p-6">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-medium text-gray-900">Feedback</h3>
                  {score !== null && (
                    <div className="flex items-center">
                      <Star className={`h-5 w-5 ${
                        score >= 80 ? 'text-yellow-400' :
                        score >= 60 ? 'text-blue-400' :
                        'text-gray-400'
                      }`} />
                      <span className="ml-2 text-lg font-semibold text-gray-900">{score}/100</span>
                    </div>
                  )}
                </div>
                <div className="prose prose-blue max-w-none">
                  {feedback.split('\n').map((line, index) => (
                    <p key={index} className="text-gray-600">{line}</p>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}