import React, { useState } from 'react';
import { Target, Users, Car, DollarSign, Loader2 } from 'lucide-react';
import { useAI } from '../ai/AIContext';

interface Campaign {
  id: string;
  name: string;
  targetAudience: string;
  budget: number;
  status: 'draft' | 'active' | 'paused' | 'completed';
  startDate: string;
  endDate: string;
  metrics: {
    reach: number;
    leads: number;
    conversions: number;
    roi: number;
  };
}

export function ConquestCampaigns() {
  const { generateResponse, isLoading } = useAI();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [showForm, setShowForm] = useState(false);

  const generateCampaignSuggestion = async (targetMarket: string) => {
    const prompt = `Generate conquest campaign recommendations for:
      Target Market: ${targetMarket}
      Include:
      1. Target audience definition
      2. Key messaging points
      3. Channel recommendations
      4. Budget allocation
      5. Success metrics`;

    const response = await generateResponse(prompt);
    if (response) {
      // Parse and use the suggestions
      console.log(response);
    }
  };

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h2 className="text-xl font-semibold text-gray-900">Conquest Campaigns</h2>
          <p className="mt-1 text-sm text-gray-500">
            Target and acquire new customers
          </p>
        </div>
        <button
          onClick={() => setShowForm(true)}
          className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
        >
          <Target className="-ml-0.5 h-5 w-5" />
          New Campaign
        </button>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600">Active Campaigns</p>
              <p className="mt-1 text-3xl font-semibold text-gray-900">
                {campaigns.filter(c => c.status === 'active').length}
              </p>
            </div>
            <div className="h-12 w-12 rounded-full bg-blue-50 flex items-center justify-center">
              <Target className="h-6 w-6 text-blue-600" />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600">Total Reach</p>
              <p className="mt-1 text-3xl font-semibold text-gray-900">
                {campaigns.reduce((sum, c) => sum + c.metrics.reach, 0).toLocaleString()}
              </p>
            </div>
            <div className="h-12 w-12 rounded-full bg-green-50 flex items-center justify-center">
              <Users className="h-6 w-6 text-green-600" />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600">New Leads</p>
              <p className="mt-1 text-3xl font-semibold text-gray-900">
                {campaigns.reduce((sum, c) => sum + c.metrics.leads, 0)}
              </p>
            </div>
            <div className="h-12 w-12 rounded-full bg-purple-50 flex items-center justify-center">
              <Car className="h-6 w-6 text-purple-600" />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600">Average ROI</p>
              <p className="mt-1 text-3xl font-semibold text-gray-900">
                {(campaigns.reduce((sum, c) => sum + c.metrics.roi, 0) / Math.max(campaigns.length, 1)).toFixed(1)}x
              </p>
            </div>
            <div className="h-12 w-12 rounded-full bg-yellow-50 flex items-center justify-center">
              <DollarSign className="h-6 w-6 text-yellow-600" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium text-gray-900">Active Campaigns</h3>
          <div className="mt-4 divide-y divide-gray-200">
            {campaigns.map(campaign => (
              <div key={campaign.id} className="py-4">
                <div className="flex items-center justify-between">
                  <div>
                    <h4 className="text-sm font-medium text-gray-900">{campaign.name}</h4>
                    <p className="mt-1 text-sm text-gray-500">{campaign.targetAudience}</p>
                  </div>
                  <div className="flex items-center space-x-4">
                    <div className="text-sm text-gray-500">
                      Budget: ${campaign.budget.toLocaleString()}
                    </div>
                    <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                      campaign.status === 'active' 
                        ? 'bg-green-100 text-green-800'
                        : 'bg-gray-100 text-gray-800'
                    }`}>
                      {campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)}
                    </span>
                  </div>
                </div>
                <div className="mt-4 grid grid-cols-4 gap-4">
                  <div className="text-center">
                    <p className="text-sm font-medium text-gray-900">
                      {campaign.metrics.reach.toLocaleString()}
                    </p>
                    <p className="text-xs text-gray-500">Reach</p>
                  </div>
                  <div className="text-center">
                    <p className="text-sm font-medium text-gray-900">
                      {campaign.metrics.leads}
                    </p>
                    <p className="text-xs text-gray-500">Leads</p>
                  </div>
                  <div className="text-center">
                    <p className="text-sm font-medium text-gray-900">
                      {campaign.metrics.conversions}
                    </p>
                    <p className="text-xs text-gray-500">Conversions</p>
                  </div>
                  <div className="text-center">
                    <p className="text-sm font-medium text-gray-900">
                      {campaign.metrics.roi}x
                    </p>
                    <p className="text-xs text-gray-500">ROI</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}