import React, { useState } from 'react';
import { Calendar, Users, Car, Megaphone, Plus, Loader2 } from 'lucide-react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

interface Event {
  id: string;
  title: string;
  start: string;
  end: string;
  type: 'test_drive' | 'sales_event' | 'vip_preview';
  description: string;
  expectedAttendees: number;
  budget: number;
}

export function EventManager() {
  const [events, setEvents] = useState<Event[]>([
    {
      id: '1',
      title: 'Spring Test Drive Event',
      start: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(),
      end: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000 + 4 * 60 * 60 * 1000).toISOString(),
      type: 'test_drive',
      description: 'Test drive event featuring new 2024 models',
      expectedAttendees: 50,
      budget: 5000
    },
    {
      id: '2', 
      title: 'VIP Preview Night',
      start: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toISOString(),
      end: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000 + 3 * 60 * 60 * 1000).toISOString(),
      type: 'vip_preview',
      description: 'Exclusive preview of upcoming models',
      expectedAttendees: 30,
      budget: 7500
    }
  ]);
  const [showEventForm, setShowEventForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

  const handleDateSelect = (selectInfo: any) => {
    setShowEventForm(true);
    // Pre-fill form with selected dates
  };

  const handleEventClick = (clickInfo: any) => {
    setSelectedEvent(events.find(e => e.id === clickInfo.event.id) || null);
  };

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h2 className="text-xl font-semibold text-gray-900">Event Management</h2>
          <p className="mt-1 text-sm text-gray-500">
            Schedule and manage dealership events
          </p>
        </div>
        <button
          onClick={() => setShowEventForm(true)}
          className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
        >
          <Plus className="-ml-0.5 h-5 w-5" />
          New Event
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        <div className="lg:col-span-3">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              weekends={true}
              events={events}
              select={handleDateSelect}
              eventClick={handleEventClick}
              height="auto"
            />
          </div>
        </div>

        <div className="space-y-4">
          <div className="bg-white rounded-lg shadow-sm p-4">
            <h3 className="text-sm font-medium text-gray-900">Upcoming Events</h3>
            <div className="mt-4 space-y-2">
              {events.slice(0, 5).map(event => (
                <div key={event.id} className="flex items-center p-2 hover:bg-gray-50 rounded-md">
                  {event.type === 'test_drive' ? (
                    <Car className="h-5 w-5 text-blue-500 mr-2" />
                  ) : event.type === 'sales_event' ? (
                    <Megaphone className="h-5 w-5 text-green-500 mr-2" />
                  ) : (
                    <Users className="h-5 w-5 text-purple-500 mr-2" />
                  )}
                  <div>
                    <p className="text-sm font-medium text-gray-900">{event.title}</p>
                    <p className="text-xs text-gray-500">
                      {new Date(event.start).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-sm p-4">
            <h3 className="text-sm font-medium text-gray-900">Quick Stats</h3>
            <div className="mt-4 grid grid-cols-2 gap-4">
              <div className="bg-blue-50 p-3 rounded-lg">
                <p className="text-xs text-blue-600">Upcoming</p>
                <p className="text-lg font-semibold text-blue-900">{events.length}</p>
              </div>
              <div className="bg-green-50 p-3 rounded-lg">
                <p className="text-xs text-green-600">This Month</p>
                <p className="text-lg font-semibold text-green-900">
                  {events.filter(e => new Date(e.start).getMonth() === new Date().getMonth()).length}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}