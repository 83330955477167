const sampleTips = [
  // Building Trust & Rapport
  "Focus on building genuine relationships before discussing vehicles",
  "Practice active listening - let customers share their needs fully",
  "Use the customer's name naturally throughout the conversation",
  "Share relevant personal experiences to build connection",
  "Maintain eye contact and positive body language",
  
  // Product Knowledge
  "Stay updated on competitor models and features",
  "Know your inventory's unique selling propositions",
  "Be prepared to explain technical features in simple terms",
  "Keep up with industry news and market trends",
  "Learn the history and heritage of your brands",
  
  // Needs Assessment
  "Ask open-ended questions about lifestyle and usage",
  "Identify both stated and unstated customer needs",
  "Use the FORM technique: Family, Occupation, Recreation, Motivation",
  "Take detailed notes during customer conversations",
  "Validate your understanding by summarizing key points",
  
  // Presentation Skills
  "Customize your presentation to customer priorities",
  "Tell stories about features that resonate with customer needs",
  "Use analogies to explain complex features",
  "Demonstrate enthusiasm for the product",
  "Focus on benefits rather than just features",
  
  // Test Drive
  "Plan your test drive route to showcase vehicle strengths",
  "Explain features during natural pauses in the drive",
  "Let customers discover vehicle benefits themselves",
  "Ask engaging questions during the drive",
  "End test drives on a high note near the dealership",
  
  // Objection Handling
  "Welcome objections as opportunities to provide information",
  "Use the feel-felt-found method for common concerns",
  "Address objections with confidence and evidence",
  "Know your financing options and terms thoroughly",
  "Practice responses to common objections",
  
  // Closing Techniques
  "Look for buying signals throughout the interaction",
  "Use assumptive closes when appropriate",
  "Present multiple ownership options",
  "Create urgency without being pushy",
  "Always ask for the sale",
  
  // Follow-up
  "Send personalized thank-you notes after visits",
  "Keep detailed records of all customer interactions",
  "Use video messages for personal touch",
  "Stay in touch with seasonal maintenance reminders",
  "Ask for referrals from satisfied customers",
  
  // Digital Sales
  "Master virtual showroom presentations",
  "Use screen sharing effectively for online walkarounds",
  "Create engaging video content for prospects",
  "Leverage social media for relationship building",
  "Stay responsive on all digital channels",
  
  // Time Management
  "Prioritize hot leads and active customers",
  "Block time for prospecting and follow-up",
  "Use CRM tools effectively",
  "Set daily and weekly goals",
  "Track your activities and results",
  
  // Professional Development
  "Read industry publications regularly",
  "Attend manufacturer training sessions",
  "Learn from successful colleagues",
  "Practice role-playing scenarios",
  "Seek feedback from managers and peers",
  
  // Customer Experience
  "Make every customer feel special and valued",
  "Go above and beyond with small gestures",
  "Remember and reference personal details",
  "Be proactive with updates and information",
  "Create memorable delivery experiences",
  
  // Negotiation
  "Focus on value over price",
  "Know your bottom line before negotiations",
  "Use silence effectively in negotiations",
  "Present numbers clearly and confidently",
  "Look for win-win solutions",
  
  // Personal Brand
  "Maintain a professional online presence",
  "Dress appropriately for your market",
  "Keep your workspace organized and welcoming",
  "Build a strong reputation in your community",
  "Network with other professionals"
] as const;

export { sampleTips };