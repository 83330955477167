import React, { useState } from 'react';
import { Tabs } from '../components/communications/Tabs';
import { EventManager } from '../components/marketing/EventManager';
import { SocialMediaDashboard } from '../components/marketing/SocialMediaDashboard';
import { ReviewManager } from '../components/marketing/ReviewManager';
import { ConquestCampaigns } from '../components/marketing/ConquestCampaigns'; 
import { motion } from 'framer-motion';

export function MarketingAutomation() {
  const [activeTab, setActiveTab] = useState('events');

  const tabs = [
    { id: 'events', name: 'Events', component: EventManager },
    { id: 'social', name: 'Social Media', component: SocialMediaDashboard },
    { id: 'reviews', name: 'Reviews', component: ReviewManager },
    { id: 'conquest', name: 'Conquest', component: ConquestCampaigns }
  ];

  const ActiveComponent = tabs.find(tab => tab.id === activeTab)?.component || EventManager;

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="space-y-6"
    >
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Marketing Automation</h1>
          <p className="mt-1 text-sm text-gray-500">
            Manage your marketing campaigns and events
          </p>
        </div>
      </div>

      <Tabs
        tabs={tabs.map(tab => ({ id: tab.id, name: tab.name }))}
        activeTab={activeTab}
        onTabChange={setActiveTab}
      />

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        className="mt-6"
      >
        <ActiveComponent />
      </motion.div>
    </motion.div>
  );
}