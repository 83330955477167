import React from 'react';
import { TrendingUp, Users, Clock, Calendar, DollarSign, Car } from 'lucide-react';
import { motion } from 'framer-motion';

export function QuickStats() {
  const stats = [
    {
      name: 'Active Leads',
      value: '124',
      change: '+12%',
      changeType: 'increase',
      icon: Users,
      gradient: 'from-blue-500 to-indigo-600'
    },
    {
      name: 'Avg Response Time',
      value: '2.4h',
      change: '-18%',
      changeType: 'decrease',
      icon: Clock,
      gradient: 'from-emerald-500 to-teal-600'
    },
    {
      name: 'Monthly Revenue',
      value: '$1.2M',
      change: '+8%',
      changeType: 'increase',
      icon: DollarSign,
      gradient: 'from-purple-500 to-pink-600'
    },
    {
      name: 'Inventory Turnover',
      value: '45 days',
      change: '-5%',
      changeType: 'decrease',
      icon: Car,
      gradient: 'from-amber-500 to-orange-600'
    }
  ];

  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
      {stats.map((stat, index) => (
        <motion.div
          key={stat.name}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: index * 0.1 }}
          className={`overflow-hidden rounded-xl bg-gradient-to-br ${stat.gradient} text-white px-4 py-5 shadow-sm hover:shadow-md transform hover:scale-105 transition-all duration-200 sm:p-6`}
        >
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <stat.icon className="h-6 w-6 text-white/90" aria-hidden="true" />
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="truncate text-sm font-medium text-white/80">{stat.name}</dt>
                <dd className="flex items-baseline">
                  <div className="text-2xl font-bold text-white">{stat.value}</div>
                  <div className={`ml-2 flex items-baseline text-sm font-semibold text-white/90`}>
                    {stat.changeType === 'increase' ? (
                      <TrendingUp className="h-4 w-4 flex-shrink-0 self-center" />
                    ) : (
                      <TrendingUp className="h-4 w-4 flex-shrink-0 self-center transform rotate-180" />
                    )}
                    <span className="ml-1">{stat.change}</span>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
}