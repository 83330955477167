import React, { useState } from 'react';
import { BookOpen, Play, CheckCircle2, Loader2, Star, Award, Brain } from 'lucide-react';
import { useAI } from '../components/ai/AIContext';

interface Module {
  id: string;
  title: string;
  description: string;
  category: 'sales' | 'product' | 'compliance' | 'customer_service';
  difficulty: 'beginner' | 'intermediate' | 'advanced';
  duration: number;
  completed?: boolean;
  score?: number;
}

const TRAINING_MODULES: Module[] = [
  {
    id: '1',
    title: 'Sales Process Fundamentals',
    description: 'Master the core principles of automotive sales',
    category: 'sales',
    difficulty: 'beginner',
    duration: 45
  },
  {
    id: '2',
    title: 'Advanced Product Knowledge',
    description: 'Deep dive into vehicle features and technology',
    category: 'product',
    difficulty: 'intermediate',
    duration: 60
  },
  {
    id: '3',
    title: 'Compliance and Regulations',
    description: 'Essential legal and regulatory requirements',
    category: 'compliance',
    difficulty: 'advanced',
    duration: 90
  },
  {
    id: '4',
    title: 'Customer Service Excellence',
    description: 'Delivering exceptional customer experiences',
    category: 'customer_service',
    difficulty: 'intermediate',
    duration: 60
  }
];

export function TrainingModules() {
  const { generateResponse, isLoading } = useAI();
  const [selectedModule, setSelectedModule] = useState<Module | null>(null);
  const [currentLesson, setCurrentLesson] = useState<string | null>(null);
  const [quizAnswers, setQuizAnswers] = useState<string[]>([]);
  const [quizResults, setQuizResults] = useState<{
    score: number;
    feedback: string;
  } | null>(null);

  const handleStartModule = async (module: Module) => {
    setSelectedModule(module);
    const prompt = `Create a training lesson for: ${module.title}
      Description: ${module.description}
      Category: ${module.category}
      Difficulty: ${module.difficulty}

      Include:
      1. Learning objectives
      2. Key concepts
      3. Real-world examples
      4. Best practices
      5. Common challenges and solutions`;

    const response = await generateResponse(prompt);
    if (response) {
      setCurrentLesson(response);
    }
  };

  const handleSubmitQuiz = async () => {
    if (!selectedModule || !quizAnswers.length) return;

    const prompt = `Evaluate these quiz answers for the ${selectedModule.title} module:
      Answers: ${quizAnswers.join('\n')}

      Provide:
      1. Score out of 100
      2. Detailed feedback
      3. Areas for improvement
      4. Recommended next steps`;

    const response = await generateResponse(prompt);
    if (response) {
      // Extract score from response (assuming first line contains score)
      const scoreMatch = response.match(/\d+/);
      const score = scoreMatch ? parseInt(scoreMatch[0]) : 0;

      setQuizResults({
        score,
        feedback: response
      });
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center gap-2 mb-6">
          <BookOpen className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">Training Modules</h2>
        </div>

        {!selectedModule ? (
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            {TRAINING_MODULES.map((module) => (
              <div
                key={module.id}
                className="relative rounded-lg border border-gray-200 bg-white p-6 shadow-sm hover:shadow-md transition-shadow duration-200"
              >
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-medium text-gray-900">{module.title}</h3>
                  {module.completed && (
                    <CheckCircle2 className="h-5 w-5 text-green-500" />
                  )}
                </div>
                <p className="text-sm text-gray-500 mb-4">{module.description}</p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      module.difficulty === 'beginner' ? 'bg-green-100 text-green-800' :
                      module.difficulty === 'intermediate' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-red-100 text-red-800'
                    }`}>
                      {module.difficulty}
                    </span>
                    <span className="text-sm text-gray-500">{module.duration} min</span>
                  </div>
                  <button
                    onClick={() => handleStartModule(module)}
                    className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                  >
                    <Play className="h-4 w-4 mr-1.5" />
                    Start
                  </button>
                </div>
                {module.score && (
                  <div className="absolute top-4 right-4 flex items-center">
                    <Star className="h-4 w-4 text-yellow-400" />
                    <span className="ml-1 text-sm font-medium text-gray-700">{module.score}%</span>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-lg font-medium text-gray-900">{selectedModule.title}</h3>
                <p className="mt-1 text-sm text-gray-500">{selectedModule.description}</p>
              </div>
              <button
                onClick={() => {
                  setSelectedModule(null);
                  setCurrentLesson(null);
                  setQuizResults(null);
                }}
                className="text-sm text-blue-600 hover:text-blue-500"
              >
                Back to Modules
              </button>
            </div>

            {currentLesson && (
              <div className="bg-gray-50 rounded-lg p-6">
                <div className="prose prose-blue max-w-none">
                  {currentLesson.split('\n').map((line, index) => (
                    <p key={index} className="text-gray-600">{line}</p>
                  ))}
                </div>
              </div>
            )}

            <div className="space-y-4">
              <h4 className="text-sm font-medium text-gray-900">Knowledge Check</h4>
              <textarea
                value={quizAnswers.join('\n')}
                onChange={(e) => setQuizAnswers(e.target.value.split('\n'))}
                rows={4}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Enter your answers..."
              />
              <button
                onClick={handleSubmitQuiz}
                disabled={isLoading || !quizAnswers.length}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {isLoading ? (
                  <>
                    <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                    Evaluating...
                  </>
                ) : (
                  <>
                    <Brain className="-ml-1 mr-2 h-5 w-5" />
                    Submit Answers
                  </>
                )}
              </button>
            </div>

            {quizResults && (
              <div className="bg-white rounded-lg border border-gray-200 p-6">
                <div className="flex items-center justify-between mb-4">
                  <h4 className="text-lg font-medium text-gray-900">Quiz Results</h4>
                  <div className="flex items-center">
                    <Award className="h-5 w-5 text-yellow-400" />
                    <span className="ml-2 text-lg font-semibold text-gray-900">
                      {quizResults.score}%
                    </span>
                  </div>
                </div>
                <div className="prose prose-blue max-w-none">
                  {quizResults.feedback.split('\n').map((line, index) => (
                    <p key={index} className="text-gray-600">{line}</p>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}