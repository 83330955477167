import React, { useState } from 'react';
import {
  Card,
  DateRangePicker,
  DateRangePickerValue,
  BarChart,
  DonutChart,
  Title,
  Text
} from '@tremor/react';
import {
  Calendar,
  Users,
  Clock,
  TrendingUp,
  ArrowUp,
  ArrowDown,
  Filter,
  Download,
  ChevronRight,
  RefreshCw,
  DollarSign,
  Car,
  Target,
  Zap
} from 'lucide-react';
import { LeadsChart } from '../components/reports/LeadsChart';
import { SourcesChart } from '../components/reports/SourcesChart';
import { PerformanceChart } from '../components/reports/PerformanceChart';
import { ResponseTimeChart } from '../components/reports/ResponseTimeChart';
import { SalesFunnel } from '../components/reports/SalesFunnel';
import { MetricsDashboard } from '../components/reports/MetricsDashboard';
import { classNames } from '../utils/classNames';
import { motion } from 'framer-motion';

export function Reports() {
  const [dateRange, setDateRange] = useState<DateRangePickerValue>({
    from: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
    to: new Date()
  });
  const [selectedTimeframe, setSelectedTimeframe] = useState<string>('30');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [activeTab, setActiveTab] = useState<'overview' | 'leads' | 'sales' | 'inventory'>('overview');

  const timeRanges = [
    { value: '7', label: 'Last 7 days' },
    { value: '30', label: 'Last 30 days' },
    { value: '90', label: 'Last 90 days' },
    { value: '365', label: 'Last year' }
  ];

  const handleRefresh = async () => {
    setIsRefreshing(true);
    // Simulate data refresh
    await new Promise(resolve => setTimeout(resolve, 1000));
    setIsRefreshing(false);
  };

  const handleExport = () => {
    // Implement export functionality
  };

  const tabs = [
    { id: 'overview', name: 'Overview', icon: TrendingUp },
    { id: 'leads', name: 'Lead Analytics', icon: Users },
    { id: 'sales', name: 'Sales Performance', icon: DollarSign },
    { id: 'inventory', name: 'Inventory Insights', icon: Car }
  ];

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="space-y-6"
    >
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
            Analytics Dashboard
          </h1>
          <p className="mt-2 text-lg text-gray-600">
            Comprehensive analysis of your dealership's performance
          </p>
        </div>
        <div className="mt-4 sm:mt-0 flex items-center space-x-4">
          <DateRangePicker
            className="max-w-md"
            value={dateRange}
            onValueChange={setDateRange}
            locale="en-US"
            selectPlaceholder="Select date range"
          />
          <select
            value={selectedTimeframe}
            onChange={(e) => setSelectedTimeframe(e.target.value)}
            className="rounded-md border-gray-300 text-sm focus:border-blue-500 focus:ring-blue-500"
          >
            {timeRanges.map(range => (
              <option key={range.value} value={range.value}>{range.label}</option>
            ))}
          </select>
          <button
            onClick={handleRefresh}
            className={`p-2 text-gray-400 hover:text-gray-600 rounded-full ${isRefreshing ? 'animate-spin' : ''}`}
            disabled={isRefreshing}
          >
            <RefreshCw className="h-5 w-5" />
          </button>
          <button
            onClick={handleExport}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            <Download className="h-4 w-4 mr-2" />
            Export
          </button>
        </div>
      </div>

      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id as any)}
              className={classNames(
                tab.id === activeTab
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
              )}
            >
              <tab.icon
                className={classNames(
                  tab.id === activeTab
                    ? 'text-blue-500'
                    : 'text-gray-400 group-hover:text-gray-500',
                  '-ml-0.5 mr-2 h-5 w-5'
                )}
              />
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      <div className="space-y-6">
        {activeTab === 'overview' && (
          <>
            <MetricsDashboard 
              data={{
                totalLeads: 124,
                conversionRate: 35.8,
                avgResponseTime: 2.4,
                totalSales: 45,
                revenue: 1250000,
                metrics: [],
                sourceDistribution: [
                  { source: 'Website', value: 40 },
                  { source: 'Referral', value: 25 },
                  { source: 'Direct', value: 20 },
                  { source: 'Social', value: 15 }
                ]
              }}
              dateRange={dateRange}
            />
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl shadow-sm p-6"
              >
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-xl font-semibold text-gray-900">Lead Performance</h2>
                  <button className="text-blue-600 hover:text-blue-700 flex items-center text-sm font-medium">
                    View Details
                    <ChevronRight className="ml-1 h-4 w-4" />
                  </button>
                </div>
                <LeadsChart data={[]} />
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
                className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-xl shadow-sm p-6"
              >
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-xl font-semibold text-gray-900">Lead Sources</h2>
                  <button className="text-purple-600 hover:text-purple-700 flex items-center text-sm font-medium">
                    View Details
                    <ChevronRight className="ml-1 h-4 w-4" />
                  </button>
                </div>
                <SourcesChart data={[
                  { source: 'Website', value: 40 },
                  { source: 'Referral', value: 25 },
                  { source: 'Direct', value: 20 },
                  { source: 'Social', value: 15 }
                ]} />
              </motion.div>
            </div>
          </>
        )}

        {activeTab === 'leads' && (
          <div className="space-y-6">
            <Card className="p-6">
              <Title>Lead Conversion Funnel</Title>
              <SalesFunnel 
                data={[
                  { stage: 'New Leads', count: 150, value: 4500000, color: 'bg-blue-500' },
                  { stage: 'Contacted', count: 120, value: 3600000, color: 'bg-indigo-500' },
                  { stage: 'Qualified', count: 80, value: 2400000, color: 'bg-violet-500' },
                  { stage: 'Converted', count: 40, value: 1200000, color: 'bg-purple-500' }
                ]}
                totalLeads={150}
              />
            </Card>
            
            <Card className="p-6">
              <Title>Response Time Analysis</Title>
              <ResponseTimeChart data={[]} />
            </Card>
          </div>
        )}

        {activeTab === 'sales' && (
          <div className="space-y-6">
            <Card className="p-6">
              <Title>Sales Performance by Rep</Title>
              <PerformanceChart data={[]} />
            </Card>
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <Card className="p-6">
                <Title>Revenue Trends</Title>
                <BarChart
                  data={[]}
                  index="date"
                  categories={["revenue"]}
                  colors={["emerald"]}
                  valueFormatter={(value) => `$${value.toLocaleString()}`}
                />
              </Card>
              
              <Card className="p-6">
                <Title>Sales by Model</Title>
                <DonutChart
                  data={[
                    { name: "SUVs", value: 35 },
                    { name: "Sedans", value: 25 },
                    { name: "Trucks", value: 20 },
                    { name: "Luxury", value: 15 },
                    { name: "Electric", value: 5 }
                  ]}
                  category="value"
                  index="name"
                  valueFormatter={(value) => `${value}%`}
                  colors={["blue", "cyan", "indigo", "violet", "purple"]}
                />
              </Card>
            </div>
          </div>
        )}

        {activeTab === 'inventory' && (
          <div className="space-y-6">
            <Card className="p-6">
              <Title>Inventory Turnover</Title>
              <BarChart
                data={[]}
                index="model"
                categories={["avgDaysToSell"]}
                colors={["blue"]}
                valueFormatter={(value) => `${value} days`}
              />
            </Card>
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <Card className="p-6">
                <Title>Stock Level by Category</Title>
                <DonutChart
                  data={[
                    { category: "New", value: 65 },
                    { category: "Used", value: 35 }
                  ]}
                  category="value"
                  index="category"
                  valueFormatter={(value) => `${value}%`}
                  colors={["emerald", "amber"]}
                />
              </Card>
              
              <Card className="p-6">
                <Title>Price Distribution</Title>
                <BarChart
                  data={[]}
                  index="range"
                  categories={["count"]}
                  colors={["violet"]}
                  valueFormatter={(value) => value.toString()}
                />
              </Card>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
}