import { Lead } from '../../types/lead';

const AITABLE_API_KEY = import.meta.env.VITE_AITABLE_API_KEY || 'uskCyMnXsaXCxFGhvzUS7qZ';
const AITABLE_DATASHEET_ID = import.meta.env.VITE_AITABLE_DATASHEET_ID || 'dstxCUD3dmGSr0M6Ex';
const AITABLE_VIEW_ID = import.meta.env.VITE_AITABLE_VIEW_ID || 'viwHi53CVy5PR';

export const AITABLE_CONFIG = {
  baseUrl: 'https://api.aitable.ai', // Updated API endpoint
  datasheetId: AITABLE_DATASHEET_ID,
  viewId: AITABLE_VIEW_ID,
  apiKey: AITABLE_API_KEY,
  headers: {
    'Authorization': `Bearer ${AITABLE_API_KEY}`,
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
};

export function transformAITableLead(record: any): Lead {
  try {
    const fields = record.fields || {};
    const now = new Date().toISOString();
    
    // Map fields based on the actual AITable response structure
    return {
      id: record.recordId,
      name: fields.Name || 'Unknown',
      email: fields.Email || '',
      phone: fields.Phone || '',
      vehicle: `${fields.Year || ''} ${fields.Model || ''} ${fields.Trim || ''}`.trim(),
      status: 'new',
      source: 'aitable',
      createdAt: now,
      lastContact: now,
      score: 0,
      engagementMetrics: {
        emailOpens: 0,
        emailClicks: 0,
        websiteVisits: 0,
        lastInteraction: now
      },
      preferences: {
        preferredContact: 'email',
        interests: []
      },
      metadata: {
        stockNumber: fields['Stock Number'],
        vin: fields.Vin,
        price: fields.Price,
        condition: fields.Condition,
        location: fields.Location,
        sourceUrl: fields.SourceUrl,
        comment: fields.Comment
      }
    };
  } catch (error) {
    console.error('Error transforming AITable lead:', error);
    throw error;
  }
}