import React, { useState, useEffect } from 'react';
import { Phone, Pause, Play, SkipForward, X, Save, Loader2 } from 'lucide-react';
import { useSmartList } from '../../hooks/useSmartList';
import { useAgora } from '../../hooks/useAgora';
import { CallNotes } from './CallNotes';
import type { SmartListMember } from '../../types/smartList';

interface PowerDialerProps {
  listId: string;
  onClose: () => void;
}

export function PowerDialer({ listId, onClose }: PowerDialerProps) {
  const [isPaused, setIsPaused] = useState(false);
  const [currentMember, setCurrentMember] = useState<SmartListMember | null>(null);
  const [showNotes, setShowNotes] = useState(false);
  
  const { members, loading, error } = useSmartList(listId);
  const { 
    initializeCall, 
    endCall, 
    callStatus, 
    error: callError 
  } = useAgora();

  useEffect(() => {
    if (members.length > 0 && !currentMember) {
      setCurrentMember(members[0]);
    }
  }, [members]);

  const handleNext = async () => {
    if (!currentMember) return;
    
    // End current call if active
    if (callStatus === 'connected') {
      await endCall();
    }

    // Find next member
    const currentIndex = members.findIndex(m => m.id === currentMember.id);
    const nextMember = members[currentIndex + 1] || null;
    setCurrentMember(nextMember);

    if (nextMember && !isPaused) {
      initializeCall(nextMember.lead.phone);
    }
  };

  const handleTogglePause = () => {
    setIsPaused(!isPaused);
    if (callStatus === 'connected') {
      endCall();
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error</h3>
            <div className="mt-2 text-sm text-red-700">
              <p>{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-x-0 bottom-0 bg-white border-t shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <button
              onClick={handleTogglePause}
              className={`rounded-full p-3 ${
                isPaused
                  ? 'bg-green-100 text-green-600 hover:bg-green-200'
                  : 'bg-red-100 text-red-600 hover:bg-red-200'
              }`}
            >
              {isPaused ? (
                <Play className="h-6 w-6" />
              ) : (
                <Pause className="h-6 w-6" />
              )}
            </button>

            {currentMember && (
              <div>
                <h3 className="text-sm font-medium text-gray-900">
                  {currentMember.lead.name}
                </h3>
                <p className="text-sm text-gray-500">
                  {currentMember.lead.phone}
                </p>
              </div>
            )}

            {callError && (
              <p className="text-sm text-red-600">{callError}</p>
            )}
          </div>

          <div className="flex items-center space-x-2">
            <button
              onClick={() => setShowNotes(true)}
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <Save className="h-4 w-4 mr-1.5" />
              Notes
            </button>

            <button
              onClick={handleNext}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              <SkipForward className="h-4 w-4 mr-1.5" />
              Next
            </button>

            <button
              onClick={onClose}
              className="p-2 text-gray-400 hover:text-gray-500"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        {showNotes && currentMember && (
          <CallNotes
            memberId={currentMember.id}
            onClose={() => setShowNotes(false)}
            onSave={handleNext}
          />
        )}
      </div>
    </div>
  );
}