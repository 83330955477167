import React, { useState } from 'react';
import { MessageSquare, Wand2, Loader2, Copy } from 'lucide-react';
import { useAI } from '../components/ai/AIContext';

interface ScriptTemplate {
  id: string;
  name: string;
  description: string;
  category: 'greeting' | 'follow-up' | 'closing' | 'objection' | 'custom';
}

const SCRIPT_TEMPLATES: ScriptTemplate[] = [
  {
    id: 'initial-contact',
    name: 'Initial Contact',
    description: 'First message to a new lead',
    category: 'greeting'
  },
  {
    id: 'follow-up',
    name: 'Follow-up Message',
    description: 'Check in after initial contact',
    category: 'follow-up'
  },
  {
    id: 'test-drive',
    name: 'Test Drive Invitation',
    description: 'Invite customer for a test drive',
    category: 'follow-up'
  },
  {
    id: 'price-quote',
    name: 'Price Quote',
    description: 'Send pricing information',
    category: 'follow-up'
  },
  {
    id: 'closing',
    name: 'Closing Message',
    description: 'Move towards closing the sale',
    category: 'closing'
  },
  {
    id: 'objection',
    name: 'Objection Response',
    description: 'Address common objections',
    category: 'objection'
  }
];

export function ChatScripts() {
  const { generateResponse, isLoading } = useAI();
  const [selectedTemplate, setSelectedTemplate] = useState<ScriptTemplate | null>(null);
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    vehicle: '',
    interests: '',
    objection: ''
  });
  const [generatedScript, setGeneratedScript] = useState<string | null>(null);

  const handleGenerateScript = async () => {
    if (!selectedTemplate) return;

    const prompt = `Generate a professional chat/messaging script for:
      Template: ${selectedTemplate.name}
      Customer: ${customerInfo.name}
      Vehicle: ${customerInfo.vehicle}
      Interests: ${customerInfo.interests}
      ${selectedTemplate.category === 'objection' ? `Objection: ${customerInfo.objection}` : ''}

      Make it:
      1. Professional but conversational
      2. Personalized to the customer
      3. Focused on value and benefits
      4. Clear and concise
      5. Include a clear call to action`;

    const response = await generateResponse(prompt);
    if (response) {
      setGeneratedScript(response);
    }
  };

  const handleCopy = async () => {
    if (generatedScript) {
      await navigator.clipboard.writeText(generatedScript);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center gap-2 mb-6">
          <MessageSquare className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">Chat Script Generator</h2>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Script Template</label>
            <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
              {SCRIPT_TEMPLATES.map((template) => (
                <button
                  key={template.id}
                  onClick={() => setSelectedTemplate(template)}
                  className={`p-4 rounded-lg border-2 text-left transition-all ${
                    selectedTemplate?.id === template.id
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200 hover:border-blue-200'
                  }`}
                >
                  <h3 className="font-medium text-gray-900">{template.name}</h3>
                  <p className="mt-1 text-sm text-gray-500">{template.description}</p>
                  <span className={`mt-2 inline-block px-2 py-1 text-xs font-medium rounded-full ${
                    template.category === 'greeting' ? 'bg-green-100 text-green-800' :
                    template.category === 'follow-up' ? 'bg-blue-100 text-blue-800' :
                    template.category === 'closing' ? 'bg-purple-100 text-purple-800' :
                    'bg-gray-100 text-gray-800'
                  }`}>
                    {template.category}
                  </span>
                </button>
              ))}
            </div>
          </div>

          {selectedTemplate && (
            <div className="space-y-4">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Customer Name</label>
                  <input
                    type="text"
                    value={customerInfo.name}
                    onChange={(e) => setCustomerInfo({ ...customerInfo, name: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    placeholder="e.g. John Smith"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Vehicle Interest</label>
                  <input
                    type="text"
                    value={customerInfo.vehicle}
                    onChange={(e) => setCustomerInfo({ ...customerInfo, vehicle: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    placeholder="e.g. 2024 BMW M5"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Customer Interests/Notes</label>
                <textarea
                  value={customerInfo.interests}
                  onChange={(e) => setCustomerInfo({ ...customerInfo, interests: e.target.value })}
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="e.g. Interested in performance features, has family of 4"
                />
              </div>

              {selectedTemplate.category === 'objection' && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">Customer Objection</label>
                  <textarea
                    value={customerInfo.objection}
                    onChange={(e) => setCustomerInfo({ ...customerInfo, objection: e.target.value })}
                    rows={2}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    placeholder="e.g. Price is too high compared to competitor"
                  />
                </div>
              )}

              <button
                onClick={handleGenerateScript}
                disabled={isLoading}
                className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {isLoading ? (
                  <>
                    <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                    Generating Script...
                  </>
                ) : (
                  <>
                    <Wand2 className="-ml-1 mr-2 h-5 w-5" />
                    Generate Script
                  </>
                )}
              </button>
            </div>
          )}

          {generatedScript && (
            <div className="mt-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium text-gray-900">Generated Script</h3>
                <button
                  onClick={handleCopy}
                  className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  <Copy className="h-4 w-4 mr-1.5" />
                  Copy
                </button>
              </div>
              <div className="bg-gray-50 rounded-lg p-6">
                <div className="prose prose-blue max-w-none">
                  {generatedScript.split('\n').map((line, index) => (
                    <p key={index} className="text-gray-600">{line}</p>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}