import React, { useState, useRef, useEffect } from 'react';
import { Send, Video, ArrowLeft, Loader2, Star, Archive, Flag, Info } from 'lucide-react';
import { MessageStatus } from './MessageStatus';
import { formatDate } from '../../utils/formatDate';
import { sendSMS } from '../../utils/twilio';

interface Message {
  id: string;
  to: string;
  from: string;
  body: string;
  status: string;
  direction: 'inbound' | 'outbound';
  mediaUrl?: string;
  createdAt: Date;
}

interface SMSChatProps {
  messages: Message[];
  phoneNumber: string;
  onBack: () => void;
  onMessageSent: () => void;
}

const QUICK_REPLIES = [
  "I'll check and get back to you shortly",
  "Yes, that works for me",
  "Would you like to schedule a call?",
  "Thank you for your message"
];

export function SMSChat({ messages, phoneNumber, onBack, onMessageSent }: SMSChatProps) {
  const [newMessage, setNewMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [starredMessages, setStarredMessages] = useState<Set<string>>(new Set());
  const [showInfo, setShowInfo] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async (message: string) => {
    if (!message.trim()) return;
    
    // Clear any previous errors
    setError(null);
    setIsSending(true);

    try {
      const response = await sendSMS({
        to: phoneNumber,
        message: message,
        userId: 'current-user'
      });
      
      if (response.success) {
        setNewMessage('');
        onMessageSent();
      } else {
        setError(response.error || 'Failed to send message');
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to send message');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="flex flex-col h-[600px] bg-gray-50 rounded-lg">
      {/* Header */}
      <div className="flex items-center justify-between p-4 border-b bg-white rounded-t-lg">
        <button
          onClick={onBack}
          className="inline-flex items-center text-gray-500 hover:text-gray-700"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back
        </button>
        <div className="text-center">
          <h3 className="text-sm font-medium text-gray-900">{phoneNumber}</h3>
          <p className="text-xs text-gray-500">{messages.length} messages</p>
        </div>
        <div className="w-10" /> {/* Spacer for alignment */}
      </div>
      <div className="absolute top-16 right-4 flex items-center space-x-2">
        <button
          onClick={() => setShowInfo(!showInfo)}
          className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100"
          title="Contact Info"
        >
          <Info className="h-5 w-5" />
        </button>
        <button
          className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100"
          title="Archive Conversation"
        >
          <Archive className="h-5 w-5" />
        </button>
        <button
          className="p-2 text-gray-400 hover:text-red-500 rounded-full hover:bg-gray-100"
          title="Flag Conversation"
        >
          <Flag className="h-5 w-5" />
        </button>
      </div>

      {showInfo && (
        <div className="absolute top-28 right-4 w-64 bg-white rounded-lg shadow-lg p-4">
          <h4 className="text-sm font-medium text-gray-900 mb-2">Contact Information</h4>
          <div className="space-y-2">
            <p className="text-sm text-gray-500">Phone: {phoneNumber}</p>
            <p className="text-sm text-gray-500">Messages: {messages.length}</p>
            <p className="text-sm text-gray-500">First Contact: {messages[0] ? formatDate(messages[0].createdAt.toString()) : 'N/A'}</p>
          </div>
        </div>
      )}

      {/* Messages */}
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`flex ${message.direction === 'inbound' ? 'justify-start' : 'justify-end'}`}
          >
            <div
              className={`max-w-[75%] rounded-lg p-4 ${
                message.direction === 'inbound'
                  ? 'bg-white'
                  : 'bg-blue-500 text-white'
              }`}
            >
              <p className="text-sm whitespace-pre-wrap">{message.body}</p>
              <div className="flex items-center justify-end mt-1 space-x-2">
                <button
                  onClick={() => setStarredMessages(prev => {
                    const newSet = new Set(prev);
                    prev.has(message.id) ? newSet.delete(message.id) : newSet.add(message.id);
                    return newSet;
                  })}
                  className={`p-1 rounded-full ${starredMessages.has(message.id) ? 'text-yellow-500' : 'text-gray-400 hover:text-yellow-500'}`}
                >
                  <Star className="h-4 w-4" />
                </button>
              </div>
              {message.mediaUrl && (
                <div className="mt-2">
                  {message.mediaUrl.includes('video') ? (
                    <video
                      controls
                      className="rounded-md max-w-full"
                      src={message.mediaUrl}
                    />
                  ) : (
                    <img
                      src={message.mediaUrl}
                      alt="Media attachment"
                      className="rounded-md max-w-full"
                    />
                  )}
                </div>
              )}
              <div className="mt-1 flex items-center justify-end gap-2">
                <span className="text-xs opacity-75">
                  {formatDate(message.createdAt.toString())}
                </span>
                <MessageStatus status={message.status} />
              </div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Quick Replies */}
      <div className="p-2 bg-white border-t border-gray-200">
        <div className="flex gap-2 overflow-x-auto pb-2">
          {QUICK_REPLIES.map((reply, index) => (
            <button
              key={index}
              onClick={() => handleSend(reply)}
              disabled={isSending}
              className="flex-none px-3 py-1 text-sm bg-gray-100 text-gray-700 rounded-full hover:bg-gray-200 whitespace-nowrap"
            >
              {reply}
            </button>
          ))}
        </div>
      </div>

      {/* Message Input */}
      <div className="p-4 bg-white rounded-b-lg border-t">
        {error && (
          <div className="mb-2 text-sm text-red-600">
            {error}
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSend(newMessage);
          }}
          className="flex items-end gap-2"
        >
          <div className="flex-1">
            <textarea
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type your message..."
              rows={1}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm resize-none"
              style={{ minHeight: '2.5rem', maxHeight: '8rem' }}
            />
          </div>
          <button
            type="submit"
            disabled={isSending || !newMessage.trim()}
            className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50"
          >
            {isSending ? (
              <Loader2 className="h-5 w-5 animate-spin" />
            ) : (
              <Send className="h-5 w-5" />
            )}
          </button>
        </form>
      </div>
    </div>
  );
}