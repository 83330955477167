import { useState, useEffect } from 'react';
import { getSmartListMembers } from '../services/smartListService';
import type { SmartListMember } from '../types/smartList';

export function useSmartList(listId: string) {
  const [members, setMembers] = useState<SmartListMember[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadMembers = async () => {
      try {
        setLoading(true);
        const data = await getSmartListMembers(listId);
        setMembers(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load members');
      } finally {
        setLoading(false);
      }
    };

    if (listId) {
      loadMembers();
    }
  }, [listId]);

  return { members, loading, error };
}