import React, { useState } from 'react';
import { FileText, Wand2, Loader2, Copy } from 'lucide-react';
import { useAI } from '../components/ai/AIContext';

interface DocumentTemplate {
  id: string;
  name: string;
  description: string;
  category: 'proposal' | 'agreement' | 'letter' | 'report';
}

const DOCUMENT_TEMPLATES: DocumentTemplate[] = [
  {
    id: 'vehicle-proposal',
    name: 'Vehicle Proposal',
    description: 'Detailed vehicle offer with pricing and features',
    category: 'proposal'
  },
  {
    id: 'buyer-agreement',
    name: 'Buyer Agreement',
    description: 'Purchase agreement with terms and conditions',
    category: 'agreement'
  },
  {
    id: 'follow-up-letter',
    name: 'Follow-up Letter',
    description: 'Professional follow-up after vehicle inquiry',
    category: 'letter'
  },
  {
    id: 'value-report',
    name: 'Value Report',
    description: 'Detailed analysis of vehicle value and benefits',
    category: 'report'
  }
];

export function DocumentGenerator() {
  const { generateResponse, isLoading } = useAI();
  const [selectedTemplate, setSelectedTemplate] = useState<DocumentTemplate | null>(null);
  const [documentInfo, setDocumentInfo] = useState({
    customerName: '',
    vehicleInfo: '',
    terms: '',
    additionalDetails: ''
  });
  const [generatedDocument, setGeneratedDocument] = useState<string | null>(null);

  const handleGenerateDocument = async () => {
    if (!selectedTemplate) return;

    const prompt = `Generate a professional ${selectedTemplate.name} for:
      Customer: ${documentInfo.customerName}
      Vehicle: ${documentInfo.vehicleInfo}
      Terms: ${documentInfo.terms}
      Additional Details: ${documentInfo.additionalDetails}

      Make it:
      1. Professional and formal
      2. Detailed and specific
      3. Well-structured with clear sections
      4. Include all necessary information
      5. Use appropriate legal/business language`;

    const response = await generateResponse(prompt);
    if (response) {
      setGeneratedDocument(response);
    }
  };

  const handleCopy = async () => {
    if (generatedDocument) {
      await navigator.clipboard.writeText(generatedDocument);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center gap-2 mb-6">
          <FileText className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">Document Generator</h2>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Document Type</label>
            <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2">
              {DOCUMENT_TEMPLATES.map((template) => (
                <button
                  key={template.id}
                  onClick={() => setSelectedTemplate(template)}
                  className={`p-4 rounded-lg border-2 text-left transition-all ${
                    selectedTemplate?.id === template.id
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200 hover:border-blue-200'
                  }`}
                >
                  <h3 className="font-medium text-gray-900">{template.name}</h3>
                  <p className="mt-1 text-sm text-gray-500">{template.description}</p>
                  <span className={`mt-2 inline-block px-2 py-1 text-xs font-medium rounded-full ${
                    template.category === 'proposal' ? 'bg-green-100 text-green-800' :
                    template.category === 'agreement' ? 'bg-blue-100 text-blue-800' :
                    template.category === 'letter' ? 'bg-purple-100 text-purple-800' :
                    'bg-gray-100 text-gray-800'
                  }`}>
                    {template.category}
                  </span>
                </button>
              ))}
            </div>
          </div>

          {selectedTemplate && (
            <div className="space-y-4">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Customer Name</label>
                  <input
                    type="text"
                    value={documentInfo.customerName}
                    onChange={(e) => setDocumentInfo({ ...documentInfo, customerName: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    placeholder="e.g. John Smith"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Vehicle Information</label>
                  <input
                    type="text"
                    value={documentInfo.vehicleInfo}
                    onChange={(e) => setDocumentInfo({ ...documentInfo, vehicleInfo: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    placeholder="e.g. 2024 BMW M5"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Terms & Conditions</label>
                <textarea
                  value={documentInfo.terms}
                  onChange={(e) => setDocumentInfo({ ...documentInfo, terms: e.target.value })}
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="e.g. Price, payment terms, delivery details"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Additional Details</label>
                <textarea
                  value={documentInfo.additionalDetails}
                  onChange={(e) => setDocumentInfo({ ...documentInfo, additionalDetails: e.target.value })}
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Any other relevant information"
                />
              </div>

              <button
                onClick={handleGenerateDocument}
                disabled={isLoading}
                className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {isLoading ? (
                  <>
                    <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                    Generating Document...
                  </>
                ) : (
                  <>
                    <Wand2 className="-ml-1 mr-2 h-5 w-5" />
                    Generate Document
                  </>
                )}
              </button>
            </div>
          )}

          {generatedDocument && (
            <div className="mt-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium text-gray-900">Generated Document</h3>
                <button
                  onClick={handleCopy}
                  className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  <Copy className="h-4 w-4 mr-1.5" />
                  Copy
                </button>
              </div>
              <div className="bg-gray-50 rounded-lg p-6">
                <div className="prose prose-blue max-w-none">
                  {generatedDocument.split('\n').map((line, index) => (
                    <p key={index} className="text-gray-600">{line}</p>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}