import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

// Demo credentials for development
const DEMO_SUPABASE_URL = 'https://zkjvdymryzpydnkyqkfy.supabase.co';
const DEMO_SUPABASE_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpranZkeW1yeXpweWRua3lxa2Z5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzQ3NzkwOTgsImV4cCI6MjA1MDM1NTA5OH0.RpBxv8JPsu4VT8nmC2IZ4gOyOwA8-8nG8AY2tFBmJu0';

export const supabase = createClient(
  supabaseUrl || DEMO_SUPABASE_URL,
  supabaseKey || DEMO_SUPABASE_KEY,
  {
    auth: {
      persistSession: true,
      autoRefreshToken: true,
      detectSessionInUrl: true
    },
    global: {
      headers: { 'x-my-custom-header': 'my-app-name' }
    }
  }
);