import { InventoryItem } from '../types/inventory';

export const sampleInventory: InventoryItem[] = [
  {
    id: 'A001',
    stockNumber: 'A001',
    year: 2024,
    make: 'Audi',
    model: 'RS e-tron GT',
    trim: 'Premium Plus',
    vin: 'WAUYGAFC3KN123456',
    price: 145995,
    mileage: 50,
    description: "Experience the pinnacle of electric performance with the 2024 Audi RS e-tron GT. This stunning all-electric grand tourer combines breathtaking acceleration with luxurious comfort. Featuring Audi's latest driver assistance technologies, premium Bang & Olufsen sound system, and sustainable interior materials.",
    status: 'In Stock',
    imageUrl: 'https://images.unsplash.com/photo-1614200179396-2bdb77ebf81b?auto=format&fit=crop&w=1600&q=80',
    dealership: 'Luxury Motors Halifax',
    listingUrl: '#',
    location: {
      city: 'Halifax',
      province: 'Nova Scotia',
      postalCode: 'B3K 4X5'
    },
    bodyStyle: 'Sedan',
    condition: 'New'
  },
  {
    id: 'B001',
    stockNumber: 'B001',
    year: 2024,
    make: 'BMW',
    model: 'M5',
    trim: 'Competition',
    vin: 'WBS83CH07PCK12345',
    price: 142900,
    mileage: 25,
    description: "The 2024 BMW M5 Competition is the ultimate expression of luxury and performance. With its twin-turbocharged V8 engine, sophisticated all-wheel drive system, and premium interior appointments, this high-performance sedan sets new standards in its class.",
    status: 'In Stock',
    imageUrl: 'https://images.unsplash.com/photo-1607853554439-0069ec0f29b6?auto=format&fit=crop&w=1600&q=80',
    dealership: 'Luxury Motors Halifax',
    listingUrl: '#',
    location: {
      city: 'Halifax',
      province: 'Nova Scotia',
      postalCode: 'B3K 4X5'
    },
    bodyStyle: 'Sedan',
    condition: 'New'
  },
  {
    id: 'P001',
    stockNumber: 'P001',
    year: 2024,
    make: 'Porsche',
    model: '911',
    trim: 'GT3 RS',
    vin: 'WP0AC2A94RS789012',
    price: 223800,
    mileage: 15,
    description: "The 2024 Porsche 911 GT3 RS represents the pinnacle of naturally aspirated performance. Track-focused yet road-legal, it features advanced aerodynamics, a race-bred suspension system, and Porsche's renowned build quality.",
    status: 'In Stock',
    imageUrl: 'https://images.unsplash.com/photo-1611859266238-4b082a0e6dc6?auto=format&fit=crop&w=1600&q=80',
    dealership: 'Luxury Motors Halifax',
    listingUrl: '#',
    location: {
      city: 'Halifax',
      province: 'Nova Scotia',
      postalCode: 'B3K 4X5'
    },
    bodyStyle: 'Coupe',
    condition: 'New'
  },
  {
    id: 'M001',
    stockNumber: 'M001',
    year: 2024,
    make: 'Mercedes-Benz',
    model: 'AMG GT',
    trim: '63 S',
    vin: 'WDDYJ7JA3PA345678',
    price: 168995,
    mileage: 35,
    description: "The 2024 Mercedes-AMG GT 63 S combines stunning design with breathtaking performance. This four-door coupe features hand-crafted AMG engine, luxurious interior with the latest MBUX technology, and exceptional handling characteristics.",
    status: 'In Stock',
    imageUrl: 'https://images.unsplash.com/photo-1617814076367-b759c7d7e738?auto=format&fit=crop&w=1600&q=80',
    dealership: 'Luxury Motors Halifax',
    listingUrl: '#',
    location: {
      city: 'Halifax',
      province: 'Nova Scotia',
      postalCode: 'B3K 4X5'
    },
    bodyStyle: 'Coupe',
    condition: 'New'
  },
  {
    id: 'T001',
    stockNumber: 'T001',
    year: 2024,
    make: 'Tesla',
    model: 'Model S',
    trim: 'Plaid',
    vin: '5YJSA1E64PF901234',
    price: 129990,
    mileage: 10,
    description: "The 2024 Tesla Model S Plaid redefines electric performance with its tri-motor powertrain and advanced autopilot capabilities. Features include a 17-inch cinematic display, premium audio system, and sophisticated air filtration system.",
    status: 'In Stock',
    imageUrl: 'https://images.unsplash.com/photo-1617788138017-80ad40651399?auto=format&fit=crop&w=1600&q=80',
    dealership: 'Luxury Motors Halifax',
    listingUrl: '#',
    location: {
      city: 'Halifax',
      province: 'Nova Scotia',
      postalCode: 'B3K 4X5'
    },
    bodyStyle: 'Sedan',
    condition: 'New'
  },
  {
    id: 'L001',
    stockNumber: 'L001',
    year: 2024,
    make: 'Lexus',
    model: 'LX',
    trim: '600 Ultra Luxury',
    vin: 'JTJDW5C73P2123456',
    price: 129700,
    mileage: 45,
    description: "The 2024 Lexus LX 600 Ultra Luxury represents the pinnacle of SUV refinement. This flagship SUV features semi-aniline leather interior, advanced safety systems, and exceptional off-road capability without compromising on-road comfort.",
    status: 'In Stock',
    imageUrl: 'https://images.unsplash.com/photo-1656468014942-fc3f9459d6b9?auto=format&fit=crop&w=1600&q=80',
    dealership: 'Luxury Motors Halifax',
    listingUrl: '#',
    location: {
      city: 'Halifax',
      province: 'Nova Scotia',
      postalCode: 'B3K 4X5'
    },
    bodyStyle: 'SUV',
    condition: 'New'
  },
  {
    id: 'R001',
    stockNumber: 'R001',
    year: 2024,
    make: 'Range Rover',
    model: 'Sport',
    trim: 'Autobiography',
    vin: 'SALW2EF79PA567890',
    price: 139500,
    mileage: 30,
    description: "The 2024 Range Rover Sport Autobiography combines legendary off-road capability with sophisticated luxury. Features include active noise cancellation, cabin air purification, and advanced terrain response system.",
    status: 'In Stock',
    imageUrl: 'https://images.unsplash.com/photo-1606664515524-ed2f786a0bd6?auto=format&fit=crop&w=1600&q=80',
    dealership: 'Luxury Motors Halifax',
    listingUrl: '#',
    location: {
      city: 'Halifax',
      province: 'Nova Scotia',
      postalCode: 'B3K 4X5'
    },
    bodyStyle: 'SUV',
    condition: 'New'
  },
  {
    id: 'G001',
    stockNumber: 'G001',
    year: 2024,
    make: 'Genesis',
    model: 'G90',
    trim: 'Ultimate',
    vin: 'KMTNA4KC2PU234567',
    price: 99900,
    mileage: 20,
    description: "The 2024 Genesis G90 Ultimate represents Korean luxury at its finest. This flagship sedan features advanced driver assistance systems, premium Nappa leather interior, and a powerful twin-turbocharged engine.",
    status: 'In Stock',
    imageUrl: 'https://images.unsplash.com/photo-1669224869332-fd0308f0681d?auto=format&fit=crop&w=1600&q=80',
    dealership: 'Luxury Motors Halifax',
    listingUrl: '#',
    location: {
      city: 'Halifax',
      province: 'Nova Scotia',
      postalCode: 'B3K 4X5'
    },
    bodyStyle: 'Sedan',
    condition: 'New'
  },
  {
    id: 'LC001',
    stockNumber: 'LC001',
    year: 2024,
    make: 'Lucid',
    model: 'Air',
    trim: 'Dream Edition',
    vin: 'L4C1D2A1R2P345678',
    price: 169000,
    mileage: 5,
    description: "The 2024 Lucid Air Dream Edition sets new standards for electric luxury vehicles. With its industry-leading range, innovative technology, and exceptional performance, this premium electric sedan represents the future of sustainable luxury.",
    status: 'In Stock',
    imageUrl: 'https://images.unsplash.com/photo-1633284714985-5be50c048873?auto=format&fit=crop&w=1600&q=80',
    dealership: 'Luxury Motors Halifax',
    listingUrl: '#',
    location: {
      city: 'Halifax',
      province: 'Nova Scotia',
      postalCode: 'B3K 4X5'
    },
    bodyStyle: 'Sedan',
    condition: 'New'
  },
  {
    id: 'RR001',
    stockNumber: 'RR001',
    year: 2024,
    make: 'Rolls-Royce',
    model: 'Ghost',
    trim: 'Extended',
    vin: 'SCA7M2CD0PU890123',
    price: 398850,
    mileage: 8,
    description: "The 2024 Rolls-Royce Ghost Extended represents the pinnacle of automotive luxury. This handcrafted masterpiece features starlight headliner, champagne cooler, and the legendary 'magic carpet ride' suspension system.",
    status: 'In Stock',
    imageUrl: 'https://images.unsplash.com/photo-1631295868223-63265b40d9e4?auto=format&fit=crop&w=1600&q=80',
    dealership: 'Luxury Motors Halifax',
    listingUrl: '#',
    location: {
      city: 'Halifax',
      province: 'Nova Scotia',
      postalCode: 'B3K 4X5'
    },
    bodyStyle: 'Sedan',
    condition: 'New'
  }
];

export const generateSampleInventory = () => sampleInventory;