import React, { useState } from 'react';
import { Share2, MessageSquare, Heart, BarChart2, RefreshCw } from 'lucide-react';
import { BarChart, Card, Title, Text } from '@tremor/react';

interface SocialStats {
  platform: string;
  followers: number;
  engagement: number;
  posts: number;
  reach: number;
}

export function SocialMediaDashboard() {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState<SocialStats[]>([
    { platform: 'Facebook', followers: 5200, engagement: 3.2, posts: 45, reach: 12000 },
    { platform: 'Instagram', followers: 8500, engagement: 4.8, posts: 78, reach: 25000 },
    { platform: 'Twitter', followers: 3800, engagement: 2.1, posts: 120, reach: 9000 },
    { platform: 'LinkedIn', followers: 2100, engagement: 1.8, posts: 25, reach: 5000 }
  ]);

  const engagementData = stats.map(stat => ({
    platform: stat.platform,
    'Engagement Rate': stat.engagement,
    Reach: stat.reach / 1000 // Convert to thousands for better visualization
  }));

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h2 className="text-xl font-semibold text-gray-900">Social Media Performance</h2>
          <p className="mt-1 text-sm text-gray-500">
            Track engagement across social platforms
          </p>
        </div>
        <button
          onClick={() => {}}
          className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <RefreshCw className="-ml-0.5 h-5 w-5" />
          Refresh Stats
        </button>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        {stats.map((stat) => (
          <div key={stat.platform} className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-600">{stat.platform}</p>
                <p className="mt-1 text-3xl font-semibold text-gray-900">
                  {stat.followers.toLocaleString()}
                </p>
              </div>
              <div className="h-12 w-12 rounded-full bg-blue-50 flex items-center justify-center">
                <Share2 className="h-6 w-6 text-blue-600" />
              </div>
            </div>
            <div className="mt-4 flex items-center justify-between text-sm">
              <div>
                <p className="text-gray-500">Posts</p>
                <p className="font-medium">{stat.posts}</p>
              </div>
              <div>
                <p className="text-gray-500">Engagement</p>
                <p className="font-medium">{stat.engagement}%</p>
              </div>
              <div>
                <p className="text-gray-500">Reach</p>
                <p className="font-medium">{(stat.reach / 1000).toFixed(1)}k</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
        <Card>
          <Title>Engagement by Platform</Title>
          <BarChart
            data={engagementData}
            index="platform"
            categories={['Engagement Rate', 'Reach']}
            colors={['blue', 'indigo']}
            valueFormatter={(value) => `${value}${value < 100 ? '%' : 'k'}`}
            yAxisWidth={48}
          />
        </Card>

        <Card>
          <Title>Recent Activity</Title>
          <div className="mt-4 space-y-4">
            {[
              { type: 'comment', platform: 'Facebook', content: 'Great service at your dealership!' },
              { type: 'like', platform: 'Instagram', content: 'New Model Launch Post' },
              { type: 'share', platform: 'LinkedIn', content: 'Customer Success Story' }
            ].map((activity, index) => (
              <div key={index} className="flex items-start space-x-3">
                {activity.type === 'comment' ? (
                  <MessageSquare className="h-5 w-5 text-blue-500" />
                ) : activity.type === 'like' ? (
                  <Heart className="h-5 w-5 text-red-500" />
                ) : (
                  <Share2 className="h-5 w-5 text-green-500" />
                )}
                <div>
                  <p className="text-sm font-medium text-gray-900">
                    {activity.platform}
                  </p>
                  <p className="text-sm text-gray-500">{activity.content}</p>
                </div>
              </div>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
}