import { useState, useCallback } from 'react';
import AgoraRTC, { IAgoraRTCClient, ICameraVideoTrack, IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng';

const APP_ID = 'd42d5ac2f9234523b97b2f9f34aaff77';

export function useAgora() {
  const [client] = useState<IAgoraRTCClient>(() => 
    AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })
  );
  const [localAudioTrack, setLocalAudioTrack] = useState<IMicrophoneAudioTrack | null>(null);
  const [localVideoTrack, setLocalVideoTrack] = useState<ICameraVideoTrack | null>(null);
  const [callStatus, setCallStatus] = useState<'idle' | 'connecting' | 'connected' | 'error'>('idle');
  const [error, setError] = useState<string | null>(null);

  const initializeCall = useCallback(async (phoneNumber: string) => {
    try {
      setCallStatus('connecting');
      setError(null);

      // Generate a unique channel name based on the phone number
      const channelName = `call-${phoneNumber}-${Date.now()}`;
      
      // Join the channel
      const uid = await client.join(APP_ID, channelName, null, null);

      // Create and publish tracks
      const [audioTrack, videoTrack] = await Promise.all([
        AgoraRTC.createMicrophoneAudioTrack(),
        AgoraRTC.createCameraVideoTrack()
      ]);

      await client.publish([audioTrack, videoTrack]);
      
      setLocalAudioTrack(audioTrack);
      setLocalVideoTrack(videoTrack);
      setCallStatus('connected');

    } catch (err) {
      console.error('Error initializing call:', err);
      setError(err instanceof Error ? err.message : 'Failed to initialize call');
      setCallStatus('error');
    }
  }, [client]);

  const endCall = useCallback(async () => {
    try {
      localAudioTrack?.close();
      localVideoTrack?.close();
      await client.leave();
      
      setLocalAudioTrack(null);
      setLocalVideoTrack(null);
      setCallStatus('idle');
      setError(null);
    } catch (err) {
      console.error('Error ending call:', err);
      setError(err instanceof Error ? err.message : 'Failed to end call');
    }
  }, [client, localAudioTrack, localVideoTrack]);

  return {
    initializeCall,
    endCall,
    callStatus,
    error,
    localAudioTrack,
    localVideoTrack
  };
}