import { supabase } from '../../lib/supabase';
import type { Lead } from '../../types/lead';
import { sampleLeads } from '../../data/sampleLeads';
import { getAITableLeads, syncAITableLeads } from '../aitable/leads';

export async function getLeads(): Promise<Lead[]> {
  try {
    // First try to get leads from Supabase
    const { data: leads, error } = await supabase
      .from('leads')
      .select(`
        id,
        name,
        email,
        phone,
        vehicle,
        status,
        source,
        score,
        engagement_metrics,
        preferences,
        last_contact,
        created_at
      `)
      .order('created_at', { ascending: false });

    if (!error && leads?.length) {
      // Try to sync with AITable in the background
      try {
        syncAITableLeads().catch(console.warn);
      } catch (syncError) {
        console.warn('Background AITable sync failed:', syncError);
      }

      return leads.map(lead => ({
        id: lead.id,
        name: lead.name,
        email: lead.email,
        phone: lead.phone,
        vehicle: lead.vehicle,
        status: lead.status,
        source: lead.source,
        createdAt: lead.created_at,
        lastContact: lead.last_contact,
        score: lead.score,
        engagementMetrics: lead.engagement_metrics,
        preferences: lead.preferences
      }));
    }

    // If no Supabase data, try AITable
    try {
      const aitableLeads = await getAITableLeads();
      if (aitableLeads.length > 0) {
        return aitableLeads;
      }
    } catch (aitableError) {
      console.warn('Failed to fetch from AITable:', aitableError);
    }

    // Fallback to sample data if both sources fail
    console.warn('Using sample data as fallback');
    return sampleLeads;
  } catch (error) {
    console.error('Error fetching leads:', error);
    return sampleLeads;
  }
}

export async function createLead(lead: Omit<Lead, 'id' | 'createdAt'>): Promise<Lead> {
  try {
    // Normalize phone and email
    const phone = lead.phone?.replace(/[^0-9+]/g, '');
    const email = lead.email?.toLowerCase().trim();

    // Check for existing lead
    const { data: existing } = await supabase
      .from('leads')
      .select('id')
      .or(`phone.eq.${phone},email.eq.${email}`)
      .maybeSingle();

    if (existing) {
      throw new Error('Lead already exists with this phone number or email');
    }

    const newLead = {
      ...lead,
      phone,
      email,
      source: lead.source,
      vehicle: lead.vehicle,
      created_at: new Date().toISOString(),
      last_contact: new Date().toISOString()
    };

    const { data, error } = await supabase
      .from('leads')
      .insert([newLead])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating lead:', error);
    throw error;
  }
}

export async function updateLead(id: string, data: Partial<Lead>): Promise<void> {
  try {
    // Validate status if it's being updated
    if (data.status) {
      const validStatuses = ['new', 'contacted', 'qualified', 'converted', 'lost'];
      if (!validStatuses.includes(data.status)) {
        throw new Error(`Invalid status: ${data.status}`);
      }
    }

    const { error } = await supabase
      .from('leads')
      .update({
        ...data,
        updated_at: new Date().toISOString()
      })
      .eq('id', id);

    if (error) throw error;
  } catch (error) {
    console.error('Error updating lead:', error);
    throw error;
  }
}

export async function deleteLead(id: string): Promise<void> {
  try {
    const { error } = await supabase
      .from('leads')
      .delete()
      .eq('id', id);

    if (error) throw error;
  } catch (error) {
    console.error('Error deleting lead:', error);
    throw error;
  }
}