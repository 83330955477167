import React, { useState, useEffect } from 'react';
import { Plus, Search, Filter, Mail, Phone, Car, Calendar, MessageSquare, Clock } from 'lucide-react';
import { CustomerFilters } from '../components/customers/CustomerFilters';
import { CustomerList } from '../components/customers/CustomerList';
import { SMSComposerModal } from '../components/communications/SMSComposerModal';
import { useAuth } from '../context/AuthContext';
import { getCustomers } from '../services/database/customerService';
import { Customer } from '../types/customer';

export function Customers() {
  const { user } = useAuth();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedSalesRep, setSelectedSalesRep] = useState<string>('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedCustomer, setExpandedCustomer] = useState<string | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);

  useEffect(() => {
    loadCustomers();
  }, []);

  const loadCustomers = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await getCustomers();
      setCustomers(data);
    } catch (err) {
      setError('Failed to load customers. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSendSMS = (customer: Customer) => {
    setSelectedCustomer(customer);
    setShowSMSModal(true);
  };

  const filteredCustomers = customers.filter((customer) => {
    const matchesSearch =
      `${customer.firstName} ${customer.lastName}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.phone.includes(searchQuery) ||
      `${customer.vehicle.year} ${customer.vehicle.make} ${customer.vehicle.model}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

    const matchesSalesRep =
      selectedSalesRep === 'all' ||
      (user?.role === 'sales' ? customer.salesRepId === user.id : customer.salesRepId === selectedSalesRep);

    return matchesSearch && matchesSalesRep;
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Customers</h1>
          <p className="mt-1 text-sm text-gray-500">
            {filteredCustomers.length} total customers
          </p>
        </div>
        <button
          onClick={() => {/* TODO: Implement add customer */}}
          className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
        >
          <Plus className="h-4 w-4" />
          Add Customer
        </button>
      </div>

      <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
        <div className="relative flex-1 max-w-md">
          <Search className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search customers..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="block w-full rounded-md border-0 py-2.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
          />
        </div>
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="inline-flex items-center gap-x-2 rounded-md bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <Filter className="h-5 w-5 text-gray-400" />
          Filters
        </button>
      </div>

      {showFilters && (
        <div className="bg-white shadow rounded-lg p-4">
          <CustomerFilters
            searchQuery={searchQuery}
            onSearchChange={setSearchQuery}
            selectedSalesRep={selectedSalesRep}
            onSalesRepChange={setSelectedSalesRep}
            showSalesRepFilter={user?.role !== 'sales'}
          />
        </div>
      )}

      <div className="space-y-4">
        <CustomerList
          customers={filteredCustomers}
          onUpdate={() => {}}
          onDelete={() => {}}
        />
      </div>

      {showSMSModal && selectedCustomer && (
        <SMSComposerModal
          recipientName={`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
          phoneNumber={selectedCustomer.phone}
          onClose={() => {
            setShowSMSModal(false);
            setSelectedCustomer(null);
          }}
        />
      )}
    </div>
  );
}