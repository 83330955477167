import React from 'react';
import { Mail, MessageSquare } from 'lucide-react';
import { classNames } from '../../utils/classNames';

interface Tab {
  id: string;
  name: string;
}

interface TabsProps {
  tabs: Tab[];
  activeTab: string;
  onTabChange: (tab: string) => void;
}
export function Tabs({ tabs, activeTab, onTabChange }: TabsProps) {

  return (
    <div className="border-b border-gray-200">
      <nav className="-mb-px flex" aria-label="Tabs">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => onTabChange(tab.id)}
            className={classNames(
              activeTab === tab.id
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
              'group inline-flex items-center border-b-2 py-4 px-8 text-sm font-medium'
            )}
          >
            {tab.name}
          </button>
        ))}
      </nav>
    </div>
  );
}