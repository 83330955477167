import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  TrendingUp, Users, Car, Clock, Calendar, DollarSign, ArrowRight, ArrowUpRight, BarChart, Zap, Brain,
  MessageSquare, Mail, Phone, Star, Bell, FileText, Target, Gauge, Award, ChevronRight
} from 'lucide-react';
import { getLeads } from '../services/leadsService';
import { getCustomers } from '../services/customerService';
import { getInventory } from '../services/inventoryService';
import { ReminderWidget } from '../components/reminders/ReminderWidget';
import { SalesTipCard } from '../components/dashboard/SalesTipCard';
import { SalesFunnel } from '../components/reports/SalesFunnel';
import { LeadsChart } from '../components/reports/LeadsChart';
import { SourcesChart } from '../components/reports/SourcesChart';
import type { Lead } from '../types/lead';
import type { Customer } from '../types/customer';
import { motion } from 'framer-motion';
import { QuickActions } from '../components/dashboard/QuickActions';
import { QuickStats } from '../components/dashboard/QuickStats';
import { LeadsBySource } from '../components/dashboard/LeadsBySource';
import { RecentActivity } from '../components/dashboard/RecentActivity';
import { WeatherWidget } from '../components/dashboard/WeatherWidget';
import { UpcomingEvents } from '../components/dashboard/UpcomingEvents';
import { Card, Title, BarChart as TremorBarChart } from '@tremor/react';

export function Dashboard() {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [inventory, setInventory] = useState<InventoryItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [timeframe, setTimeframe] = useState<'7d' | '30d' | '90d'>('30d');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [leadsData, customersData, inventoryData] = await Promise.all([
          getLeads(),
          getCustomers(),
          getInventory()
        ]);
        
        setLeads(leadsData);
        setCustomers(customersData);
        setInventory(inventoryData);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateStats = () => {
    const totalLeads = leads.length;
    const convertedLeads = leads.filter(lead => lead.status === 'converted').length;
    const conversionRate = totalLeads > 0 ? (convertedLeads / totalLeads) * 100 : 0;
    
    const thisMonth = new Date().getMonth();
    const thisMonthLeads = leads.filter(lead => {
      const leadMonth = new Date(lead.createdAt).getMonth();
      return leadMonth === thisMonth;
    }).length;

    const lastMonthLeads = leads.filter(lead => {
      const leadMonth = new Date(lead.createdAt).getMonth();
      return leadMonth === (thisMonth - 1);
    }).length;

    const monthlyGrowth = lastMonthLeads > 0 
      ? ((thisMonthLeads - lastMonthLeads) / lastMonthLeads) * 100 
      : 0;

    return {
      totalLeads,
      activeCustomers: customers.length,
      conversionRate: conversionRate.toFixed(1),
      monthlyGrowth: monthlyGrowth.toFixed(1),
      revenue: convertedLeads * 35000 // Average sale price
    };
  };

  const funnelData = [
    { stage: 'New Leads', count: leads.length, value: leads.length * 35000, color: 'bg-blue-500' },
    { stage: 'Contacted', count: leads.filter(l => l.status === 'contacted').length, value: leads.filter(l => l.status === 'contacted').length * 35000, color: 'bg-indigo-500' },
    { stage: 'Qualified', count: leads.filter(l => l.status === 'qualified').length, value: leads.filter(l => l.status === 'qualified').length * 35000, color: 'bg-violet-500' },
    { stage: 'Converted', count: leads.filter(l => l.status === 'converted').length, value: leads.filter(l => l.status === 'converted').length * 35000, color: 'bg-purple-500' }
  ];

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brand-600"></div>
      </div>
    );
  }

  const stats = calculateStats();

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      className="space-y-6"
    >
      <div className="flex items-center justify-between">
        <div className="max-w-2xl">
          <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
            Welcome Back
          </h1>
          <p className="mt-1 text-sm text-gray-500">
            Here's what's happening today, {new Date().toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <select
            value={timeframe}
            onChange={(e) => setTimeframe(e.target.value as '7d' | '30d' | '90d')}
            className="rounded-md border-gray-300 text-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="7d">Last 7 days</option>
            <option value="30d">Last 30 days</option>
            <option value="90d">Last 90 days</option>
          </select>
          <button
            onClick={() => navigate('/reports')}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
          >
            <BarChart className="h-4 w-4 mr-2" />
            View Reports
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <QuickStats />
        </motion.div>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.1 }}
        >
          <QuickActions />
        </motion.div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.4 }}
          className="lg:col-span-3"
        >
          <SalesTipCard />
        </motion.div>

        <div className="lg:col-span-2">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.5 }}
            className="space-y-6"
          >
            <LeadsBySource />
            <ReminderWidget />
            <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
              <RecentActivity />
            </motion.div>
          </motion.div>
        </div>

        <div className="lg:col-span-1">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.5 }}
            className="space-y-6"
          >
            <WeatherWidget />
            <UpcomingEvents />
          </motion.div>
        </div>
      </div>
      
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.6 }}
        className="bg-gradient-to-r from-blue-600 to-indigo-600 rounded-lg p-6 text-white"
      >
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-2xl font-bold mb-2 flex items-center">
              <Brain className="h-6 w-6 mr-2 text-white" />
              Enhance Your Sales with AI
            </h2>
            <p className="text-blue-100 flex items-center">
              <Zap className="h-4 w-4 mr-1" />
              Access powerful AI tools for lead scoring, customer insights, and more
            </p>
          </div>
          <button onClick={() => navigate('/ai-tools')} className="bg-white text-blue-600 px-6 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors">
            <span className="flex items-center">Try AI Tools <ArrowRight className="ml-2 h-4 w-4" /></span>
          </button>
        </div>
      </motion.div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3, delay: 0.7 }}
          className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow duration-200"
        >
          <div className="flex items-center justify-between mb-6">
            <div>
              <h2 className="text-lg font-semibold text-gray-900">Sales Pipeline</h2>
              <p className="text-sm text-gray-500">Current pipeline value: {new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(funnelData.reduce((sum, item) => sum + item.value, 0))}</p>
            </div>
            <button
              onClick={() => navigate('/leads')}
              className="text-sm text-blue-600 hover:text-blue-700 flex items-center"
            >
              View All Leads
              <ChevronRight className="ml-1 h-4 w-4" />
            </button>
          </div>
          <SalesFunnel data={funnelData} totalLeads={leads.length} />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3, delay: 0.8 }}
          className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow duration-200"
        >
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-lg font-semibold text-gray-900">Lead Sources</h2>
            <button
              onClick={() => navigate('/reports')}
              className="text-sm text-blue-600 hover:text-blue-700 flex items-center"
            >
              View Details
              <ChevronRight className="ml-1 h-4 w-4" />
            </button>
          </div>
          <SourcesChart
            data={[
              { source: 'Website', value: 40 },
              { source: 'Referral', value: 25 },
              { source: 'Direct', value: 20 },
              { source: 'Social', value: 15 }
            ]}
          />
        </motion.div>
      </div>
    </motion.div>
  );
}