import React from 'react';
import { useLocation } from 'react-router-dom';
import { 
  Car, 
  Users, 
  Mail,
  MessageSquare,
  BarChart3, 
  Settings,
  Star,
  Menu,
  Bell,
  Package,
  LayoutDashboard,
  Bot,
  Search,
  Database,
  Info,
  Brain
} from 'lucide-react';
import { Sidebar } from './Sidebar';
import { TopBar } from './TopBar';
import { Footer } from './Footer';
import { FloatingChatWidget } from './assistant/FloatingChatWidget';
import { motion, AnimatePresence } from 'framer-motion';
import { NotificationCenter } from './notifications/NotificationCenter';
import { SEO } from './SEO';
import { useAuth } from '../context/AuthContext';
import { NetworkStatus } from './NetworkStatus';
import { useNavigate } from 'react-router-dom';

export function Layout({ children }: { children: React.ReactNode }) {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Don't show layout on landing page
  if (location.pathname === '/') {
    return <>{children}</>;
  }

  const navigation = [
    { name: 'About', href: '/about', icon: Info },
    { name: 'Dashboard', href: '/dashboard', icon: LayoutDashboard },
    { name: 'Leads', href: '/leads', icon: Car },
    { name: 'Customers', href: '/customers', icon: Users },
    { name: 'Inventory', href: '/inventory', icon: Package },
    { name: 'Sales Coach', href: '/sales-coach', icon: Brain },
    { name: 'Workflows', href: '/workflows', icon: Settings },
    { name: 'AI Tools', href: '#', icon: Bot, children: [
      { name: 'Sales Coach', href: '/ai-tools/sales-coach' },
      { name: 'Vehicle Recommender', href: '/ai-tools/recommender' },
      { name: 'Customer Insights', href: '/ai-tools/insights' },
      { name: 'Email Generator', href: '/ai-tools/email' }, 
      { name: 'Market Analyzer', href: '/ai-tools/market-analyzer' },
      { name: 'Competitor Analysis', href: '/ai-tools/competitor-analysis' },
      { name: 'Price Optimizer', href: '/ai-tools/price-optimizer' },
      { name: 'Inventory Optimizer', href: '/ai-tools/inventory-optimizer' },
      { name: 'Customer Segmentation', href: '/ai-tools/customer-segmentation' },
      { name: 'Service Predictor', href: '/ai-tools/service-predictor' }
    ]},
    { name: 'Email', href: '/email', icon: Mail },
    { name: 'SMS', href: '/sms', icon: MessageSquare },
    { name: 'Reports', href: '/reports', icon: BarChart3 },
    { name: 'VIN Decoder', href: '/vin-decoder', icon: Search },
    { name: 'Vehicle Database', href: '/vehicle-database', icon: Database },
    { name: 'Description Generator', href: '/vehicle-description-generator', icon: Car },
    { name: 'Reviews', href: '/reviews', icon: Star },
    { name: 'Settings', href: '/settings', icon: Settings },
  ];

  return (
    <div className="relative min-h-screen bg-background">
      <SEO />
      <Sidebar
        navigation={navigation}
        isOpen={sidebarOpen}
        setIsOpen={setSidebarOpen}
        currentPath={location.pathname}
      />
      
      <div className="lg:pl-72 min-h-screen flex flex-col">
        <TopBar onMenuClick={() => setSidebarOpen(true)} />
        
        <main className="flex-grow p-8 bg-background relative z-0">
          <AnimatePresence mode="wait">
            <motion.div
              key={location.pathname}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="mx-auto max-w-7xl relative"
            >
              {children}
            </motion.div>
          </AnimatePresence>
        </main>

        <Footer />
      </div>

      <div className="fixed bottom-4 right-4 z-50 flex flex-col items-end space-y-4">
        {location.pathname !== '/assistant' && <FloatingChatWidget />}
      </div>
      <div className="fixed bottom-4 left-4 z-50">
        <NetworkStatus />
      </div>
    </div>
  );
}