import { supabase } from '../../lib/supabase';
import type { InventoryItem } from '../../types/inventory';
import { sampleInventory } from '../../data/sampleInventory';

export async function getInventory() {
  try {
    const { data, error } = await supabase
      .from('inventory')
      .select(`
        id,
        stock_number,
        year,
        make,
        model,
        trim,
        vin,
        price,
        mileage,
        description,
        status,
        image_url,
        dealership,
        listing_url,
        location,
        body_style,
        condition
      `)
      .order('created_at', { ascending: false });

    if (error) {
      console.warn('Supabase error, using sample data:', error);
      return sampleInventory;
    }

    return data?.length ? data.map((item: any) => ({
      id: item.id,
      stockNumber: item.stock_number,
      year: item.year,
      make: item.make,
      model: item.model,
      trim: item.trim,
      vin: item.vin,
      price: item.price,
      mileage: item.mileage,
      description: item.description,
      status: item.status,
      imageUrl: item.image_url,
      dealership: item.dealership,
      listingUrl: item.listing_url,
      location: item.location,
      bodyStyle: item.body_style,
      condition: item.condition
    })) : sampleInventory;
  } catch (error) {
    console.error('Error fetching inventory:', error);
    return sampleInventory;
  }
}

export async function createInventoryItem(item: Omit<InventoryItem, 'id'>) {
  try {
    const { data, error } = await supabase
      .from('inventory')
      .insert([{
        stock_number: item.stockNumber,
        year: item.year,
        make: item.make,
        model: item.model,
        trim: item.trim,
        vin: item.vin,
        price: item.price,
        mileage: item.mileage,
        description: item.description,
        status: item.status,
        image_url: item.imageUrl,
        dealership: item.dealership,
        listing_url: item.listingUrl,
        location: item.location,
        body_style: item.bodyStyle,
        condition: item.condition
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating inventory item:', error);
    throw error;
  }
}

export async function updateInventoryItem(id: string, item: Partial<InventoryItem>) {
  try {
    const { error } = await supabase
      .from('inventory')
      .update({
        stock_number: item.stockNumber,
        year: item.year,
        make: item.make,
        model: item.model,
        trim: item.trim,
        vin: item.vin,
        price: item.price,
        mileage: item.mileage,
        description: item.description,
        status: item.status,
        image_url: item.imageUrl,
        dealership: item.dealership,
        listing_url: item.listingUrl,
        location: item.location,
        body_style: item.bodyStyle,
        condition: item.condition,
        updated_at: new Date().toISOString()
      })
      .eq('id', id);

    if (error) throw error;
  } catch (error) {
    console.error('Error updating inventory item:', error);
    throw error;
  }
}

export async function deleteInventoryItem(id: string) {
  try {
    const { error } = await supabase
      .from('inventory')
      .delete()
      .eq('id', id);

    if (error) throw error;
  } catch (error) {
    console.error('Error deleting inventory item:', error);
    throw error;
  }
}

// Real-time subscription for inventory
export function subscribeToInventory(callback: (payload: any) => void) {
  return supabase
    .channel('inventory_channel')
    .on(
      'postgres_changes',
      { event: '*', schema: 'public', table: 'inventory' },
      callback
    )
    .subscribe();
}