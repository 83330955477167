import { supabase } from '../lib/supabase';
import type { SmartList, SmartListFilter, SmartListMember } from '../types/smartList';

export async function getSmartLists(): Promise<SmartList[]> {
  try {
    const { data: lists, error } = await supabase
      .from('smart_lists')
      .select(`
        *,
        smart_list_members (
          count
        )
      `)
      .order('created_at', { ascending: false });

    if (error) throw error;

    return lists.map(list => ({
      id: list.id,
      name: list.name,
      description: list.description,
      filters: list.filters,
      userId: list.user_id,
      memberCount: list.smart_list_members?.[0]?.count || 0,
      createdAt: list.created_at,
      updatedAt: list.updated_at
    }));
  } catch (error) {
    console.error('Error fetching smart lists:', error);
    throw error;
  }
}

export async function createSmartList(list: Omit<SmartList, 'id' | 'createdAt' | 'updatedAt' | 'memberCount'>): Promise<SmartList> {
  try {
    const { data, error } = await supabase
      .from('smart_lists')
      .insert([{
        name: list.name,
        description: list.description,
        filters: list.filters,
        user_id: list.userId
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating smart list:', error);
    throw error;
  }
}

export async function updateSmartList(id: string, updates: Partial<SmartList>): Promise<void> {
  try {
    const { error } = await supabase
      .from('smart_lists')
      .update({
        name: updates.name,
        description: updates.description,
        filters: updates.filters
      })
      .eq('id', id);

    if (error) throw error;
  } catch (error) {
    console.error('Error updating smart list:', error);
    throw error;
  }
}

export async function deleteSmartList(id: string): Promise<void> {
  try {
    const { error } = await supabase
      .from('smart_lists')
      .delete()
      .eq('id', id);

    if (error) throw error;
  } catch (error) {
    console.error('Error deleting smart list:', error);
    throw error;
  }
}

export async function getSmartListMembers(listId: string): Promise<SmartListMember[]> {
  try {
    const { data: members, error } = await supabase
      .from('smart_list_members')
      .select(`
        *,
        leads (*)
      `)
      .eq('smart_list_id', listId)
      .order('added_at', { ascending: false });

    if (error) throw error;

    return members.map(member => ({
      id: member.id,
      smartListId: member.smart_list_id,
      leadId: member.lead_id,
      addedAt: member.added_at,
      lastContacted: member.last_contacted,
      status: member.status,
      lead: member.leads
    }));
  } catch (error) {
    console.error('Error fetching smart list members:', error);
    throw error;
  }
}

export async function updateMemberStatus(memberId: string, status: SmartListMember['status']): Promise<void> {
  try {
    const { error } = await supabase
      .from('smart_list_members')
      .update({ status })
      .eq('id', memberId);

    if (error) throw error;
  } catch (error) {
    console.error('Error updating member status:', error);
    throw error;
  }
}

export async function updateMemberNotes(memberId: string, data: {
  notes: string;
  outcome: string;
  callbackDate?: string;
  status: string;
}): Promise<void> {
  try {
    const { error } = await supabase
      .from('smart_list_members')
      .update({
        notes: data.notes,
        outcome: data.outcome,
        callback_date: data.callbackDate,
        status: data.status,
        updated_at: new Date().toISOString()
      })
      .eq('id', memberId);

    if (error) throw error;
  } catch (error) {
    console.error('Error updating member notes:', error);
    throw error;
  }
}

export async function updateLastContacted(memberId: string): Promise<void> {
  try {
    const { error } = await supabase
      .from('smart_list_members')
      .update({
        last_contacted: new Date().toISOString()
      })
      .eq('id', memberId);

    if (error) throw error;
  } catch (error) {
    console.error('Error updating last contacted:', error);
    throw error;
  }
}