import React, { useState } from 'react';
import { Presentation, Wand2, Loader2, Copy, Plus, X } from 'lucide-react';
import { useAI } from '../components/ai/AIContext';

interface Slide {
  id: string;
  title: string;
  content: string;
  type: 'intro' | 'features' | 'specs' | 'comparison' | 'value' | 'closing';
}

export function PresentationBuilder() {
  const { generateResponse, isLoading } = useAI();
  const [vehicleInfo, setVehicleInfo] = useState({
    make: '',
    model: '',
    year: '',
    trim: '',
    features: [] as string[],
    competitiveAdvantages: '',
    targetAudience: ''
  });
  const [newFeature, setNewFeature] = useState('');
  const [slides, setSlides] = useState<Slide[]>([]);

  const handleGeneratePresentation = async () => {
    const prompt = `Create a professional vehicle presentation for:
      Vehicle: ${vehicleInfo.year} ${vehicleInfo.make} ${vehicleInfo.model} ${vehicleInfo.trim}
      Key Features: ${vehicleInfo.features.join(', ')}
      Competitive Advantages: ${vehicleInfo.competitiveAdvantages}
      Target Audience: ${vehicleInfo.targetAudience}

      Create a presentation with:
      1. Engaging introduction
      2. Key features and benefits
      3. Technical specifications
      4. Competitive comparison
      5. Value proposition
      6. Strong closing
      
      Format each slide as: Title | Content`;

    const response = await generateResponse(prompt);
    if (response) {
      const newSlides = response.split('\n')
        .filter(line => line.trim())
        .map((line, index) => {
          const [title, content] = line.split('|').map(s => s.trim());
          return {
            id: index.toString(),
            title,
            content,
            type: index === 0 ? 'intro' :
                  index === response.split('\n').length - 1 ? 'closing' :
                  'features'
          };
        });
      setSlides(newSlides);
    }
  };

  const addFeature = () => {
    if (newFeature.trim()) {
      setVehicleInfo(prev => ({
        ...prev,
        features: [...prev.features, newFeature.trim()]
      }));
      setNewFeature('');
    }
  };

  const removeFeature = (index: number) => {
    setVehicleInfo(prev => ({
      ...prev,
      features: prev.features.filter((_, i) => i !== index)
    }));
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center gap-2 mb-6">
          <Presentation className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">Presentation Builder</h2>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">Make</label>
              <input
                type="text"
                value={vehicleInfo.make}
                onChange={(e) => setVehicleInfo({ ...vehicleInfo, make: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="e.g. BMW"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Model</label>
              <input
                type="text"
                value={vehicleInfo.model}
                onChange={(e) => setVehicleInfo({ ...vehicleInfo, model: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="e.g. M5"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Year</label>
              <input
                type="text"
                value={vehicleInfo.year}
                onChange={(e) => setVehicleInfo({ ...vehicleInfo, year: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="e.g. 2024"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Trim</label>
              <input
                type="text"
                value={vehicleInfo.trim}
                onChange={(e) => setVehicleInfo({ ...vehicleInfo, trim: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="e.g. Competition"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Key Features</label>
            <div className="mt-2 flex gap-2">
              <input
                type="text"
                value={newFeature}
                onChange={(e) => setNewFeature(e.target.value)}
                className="block flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Add a feature"
                onKeyPress={(e) => e.key === 'Enter' && addFeature()}
              />
              <button
                type="button"
                onClick={addFeature}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                <Plus className="h-4 w-4" />
              </button>
            </div>
            <div className="mt-2 flex flex-wrap gap-2">
              {vehicleInfo.features.map((feature, index) => (
                <span
                  key={index}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                >
                  {feature}
                  <button
                    type="button"
                    onClick={() => removeFeature(index)}
                    className="ml-1.5 inline-flex items-center justify-center w-4 h-4 rounded-full hover:bg-blue-200"
                  >
                    <X className="h-3 w-3" />
                  </button>
                </span>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Competitive Advantages</label>
            <textarea
              value={vehicleInfo.competitiveAdvantages}
              onChange={(e) => setVehicleInfo({ ...vehicleInfo, competitiveAdvantages: e.target.value })}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="What makes this vehicle stand out from competitors?"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Target Audience</label>
            <textarea
              value={vehicleInfo.targetAudience}
              onChange={(e) => setVehicleInfo({ ...vehicleInfo, targetAudience: e.target.value })}
              rows={2}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="Who is the ideal customer for this vehicle?"
            />
          </div>

          <button
            onClick={handleGeneratePresentation}
            disabled={isLoading}
            className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                Generating Presentation...
              </>
            ) : (
              <>
                <Wand2 className="-ml-1 mr-2 h-5 w-5" />
                Generate Presentation
              </>
            )}
          </button>
        </div>

        {slides.length > 0 && (
          <div className="mt-8">
            <h3 className="text-lg font-medium text-gray-900 mb-6">Generated Presentation</h3>
            <div className="space-y-6">
              {slides.map((slide, index) => (
                <div
                  key={slide.id}
                  className="bg-gray-50 rounded-lg p-6 border border-gray-200"
                >
                  <div className="flex items-center justify-between mb-4">
                    <h4 className="text-lg font-medium text-gray-900">
                      {index + 1}. {slide.title}
                    </h4>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      slide.type === 'intro' ? 'bg-green-100 text-green-800' :
                      slide.type === 'closing' ? 'bg-purple-100 text-purple-800' :
                      'bg-blue-100 text-blue-800'
                    }`}>
                      {slide.type}
                    </span>
                  </div>
                  <div className="prose prose-blue max-w-none">
                    {slide.content.split('\n').map((line, i) => (
                      <p key={i} className="text-gray-600">{line}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}