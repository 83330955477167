import { supabase } from '../../lib/supabase';
import { AITABLE_CONFIG, transformAITableLead } from './config';
import type { Lead } from '../../types/lead';

export async function getAITableLeads(): Promise<Lead[]> {
  try {
    // Add timeout to prevent hanging requests
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), 10000); // Increased timeout to 10s

    const url = `${AITABLE_CONFIG.baseUrl}/fusion/v1/datasheets/${AITABLE_CONFIG.datasheetId}/records`;
    const params = new URLSearchParams({
      viewId: AITABLE_CONFIG.viewId,
      fieldKey: 'name',
      pageSize: '100' // Added page size parameter
    });

    const response = await fetch(`${url}?${params}`, {
      method: 'GET',
      headers: AITABLE_CONFIG.headers,
      signal: controller.signal,
      credentials: 'omit' // Added to prevent CORS issues
    });

    clearTimeout(timeout);

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(`AITable API error: ${errorData.message || response.statusText}`);
    }

    const data = await response.json();
    
    if (!data.data?.records || !Array.isArray(data.data.records)) {
      console.warn('Invalid response format from AITable:', data);
      return [];
    }

    const leads = data.data.records.map(transformAITableLead);
    console.log('Fetched leads from AITable:', leads.length);
    return leads;
  } catch (error) {
    if (error.name === 'AbortError') {
      console.warn('AITable request timed out');
      return [];
    }
    console.error('Error fetching leads from AITable:', error);
    return [];
  }
}

export async function syncAITableLeads(): Promise<void> {
  try {
    const leads = await getAITableLeads();
    
    if (leads.length === 0) {
      console.warn('No leads found in AITable to sync');
      return;
    }

    // Store leads in Supabase with retry logic
    let retries = 3;
    while (retries > 0) {
      try {
        const { error } = await supabase
          .from('leads')
          .upsert(
            leads.map(lead => ({
              ...lead,
              source: 'aitable',
              updated_at: new Date().toISOString()
            })),
            { 
              onConflict: 'id',
              ignoreDuplicates: false
            }
          );

        if (error) throw error;
        console.log(`Successfully synced ${leads.length} leads from AITable`);
        return;
      } catch (error) {
        retries--;
        if (retries === 0) throw error;
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  } catch (error) {
    console.error('Error syncing AITable leads:', error);
    throw error;
  }
}