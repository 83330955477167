import React, { useState } from 'react';
import { Calculator, Wand2, Loader2, DollarSign, Car, Users, FileText } from 'lucide-react';
import { useAI } from '../components/ai/AIContext';

interface DealAnalysis {
  profitability: number;
  risk: 'low' | 'medium' | 'high';
  recommendations: string[];
  alternatives: string[];
}

export function DealAnalyzer() {
  const { generateResponse, isLoading } = useAI();
  const [dealInfo, setDealInfo] = useState({
    vehicleInfo: {
      make: '',
      model: '',
      year: '',
      condition: '',
      mileage: '',
      cost: '',
      listPrice: ''
    },
    customerInfo: {
      creditScore: '',
      downPayment: '',
      tradeIn: '',
      tradeInValue: '',
      desiredPayment: ''
    },
    terms: {
      loanAmount: '',
      interestRate: '',
      loanTerm: '',
      monthlyPayment: ''
    }
  });
  const [analysis, setAnalysis] = useState<DealAnalysis | null>(null);

  const handleAnalyze = async () => {
    const prompt = `Analyze this vehicle deal:
      Vehicle:
      - ${dealInfo.vehicleInfo.year} ${dealInfo.vehicleInfo.make} ${dealInfo.vehicleInfo.model}
      - Condition: ${dealInfo.vehicleInfo.condition}
      - Mileage: ${dealInfo.vehicleInfo.mileage}
      - Cost: $${dealInfo.vehicleInfo.cost}
      - List Price: $${dealInfo.vehicleInfo.listPrice}

      Customer:
      - Credit Score: ${dealInfo.customerInfo.creditScore}
      - Down Payment: $${dealInfo.customerInfo.downPayment}
      - Trade-In: ${dealInfo.customerInfo.tradeIn}
      - Trade-In Value: $${dealInfo.customerInfo.tradeInValue}
      - Desired Payment: $${dealInfo.customerInfo.desiredPayment}

      Terms:
      - Loan Amount: $${dealInfo.terms.loanAmount}
      - Interest Rate: ${dealInfo.terms.interestRate}%
      - Loan Term: ${dealInfo.terms.loanTerm} months
      - Monthly Payment: $${dealInfo.terms.monthlyPayment}

      Provide:
      1. Profitability analysis
      2. Risk assessment
      3. Deal structure recommendations
      4. Alternative scenarios
      5. Negotiation strategy`;

    const response = await generateResponse(prompt);
    if (response) {
      // Parse the response into structured data
      const lines = response.split('\n');
      const profitabilityMatch = lines.find(l => l.includes('Profitability'))?.match(/\d+/);
      const riskMatch = lines.find(l => l.toLowerCase().includes('risk'))?.toLowerCase();
      
      setAnalysis({
        profitability: profitabilityMatch ? parseInt(profitabilityMatch[0]) : 0,
        risk: riskMatch?.includes('high') ? 'high' :
              riskMatch?.includes('medium') ? 'medium' : 'low',
        recommendations: lines.filter(l => l.startsWith('-')).map(l => l.substring(2)),
        alternatives: lines.filter(l => l.startsWith('*')).map(l => l.substring(2))
      });
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center gap-2 mb-6">
          <Calculator className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">Deal Analyzer</h2>
        </div>

        <div className="space-y-6">
          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-4">
              <Car className="h-4 w-4 inline mr-2" />
              Vehicle Information
            </h3>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label className="block text-sm text-gray-500">Make</label>
                <input
                  type="text"
                  value={dealInfo.vehicleInfo.make}
                  onChange={(e) => setDealInfo({
                    ...dealInfo,
                    vehicleInfo: { ...dealInfo.vehicleInfo, make: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-500">Model</label>
                <input
                  type="text"
                  value={dealInfo.vehicleInfo.model}
                  onChange={(e) => setDealInfo({
                    ...dealInfo,
                    vehicleInfo: { ...dealInfo.vehicleInfo, model: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-500">Year</label>
                <input
                  type="text"
                  value={dealInfo.vehicleInfo.year}
                  onChange={(e) => setDealInfo({
                    ...dealInfo,
                    vehicleInfo: { ...dealInfo.vehicleInfo, year: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-500">Condition</label>
                <select
                  value={dealInfo.vehicleInfo.condition}
                  onChange={(e) => setDealInfo({
                    ...dealInfo,
                    vehicleInfo: { ...dealInfo.vehicleInfo, condition: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="">Select condition</option>
                  <option value="new">New</option>
                  <option value="certified">Certified Pre-Owned</option>
                  <option value="used">Used</option>
                </select>
              </div>

              <div>
                <label className="block text-sm text-gray-500">Mileage</label>
                <input
                  type="text"
                  value={dealInfo.vehicleInfo.mileage}
                  onChange={(e) => setDealInfo({
                    ...dealInfo,
                    vehicleInfo: { ...dealInfo.vehicleInfo, mileage: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-500">Cost</label>
                <input
                  type="text"
                  value={dealInfo.vehicleInfo.cost}
                  onChange={(e) => setDealInfo({
                    ...dealInfo,
                    vehicleInfo: { ...dealInfo.vehicleInfo, cost: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-500">List Price</label>
                <input
                  type="text"
                  value={dealInfo.vehicleInfo.listPrice}
                  onChange={(e) => setDealInfo({
                    ...dealInfo,
                    vehicleInfo: { ...dealInfo.vehicleInfo, listPrice: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-4">
              <Users className="h-4 w-4 inline mr-2" />
              Customer Information
            </h3>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label className="block text-sm text-gray-500">Credit Score</label>
                <input
                  type="text"
                  value={dealInfo.customerInfo.creditScore}
                  onChange={(e) => setDealInfo({
                    ...dealInfo,
                    customerInfo: { ...dealInfo.customerInfo, creditScore: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-500">Down Payment</label>
                <input
                  type="text"
                  value={dealInfo.customerInfo.downPayment}
                  onChange={(e) => setDealInfo({
                    ...dealInfo,
                    customerInfo: { ...dealInfo.customerInfo, downPayment: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-500">Trade-In Vehicle</label>
                <input
                  type="text"
                  value={dealInfo.customerInfo.tradeIn}
                  onChange={(e) => setDealInfo({
                    ...dealInfo,
                    customerInfo: { ...dealInfo.customerInfo, tradeIn: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-500">Trade-In Value</label>
                <input
                  type="text"
                  value={dealInfo.customerInfo.tradeInValue}
                  onChange={(e) => setDealInfo({
                    ...dealInfo,
                    customerInfo: { ...dealInfo.customerInfo, tradeInValue: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-500">Desired Monthly Payment</label>
                <input
                  type="text"
                  value={dealInfo.customerInfo.desiredPayment}
                  onChange={(e) => setDealInfo({
                    ...dealInfo,
                    customerInfo: { ...dealInfo.customerInfo, desiredPayment: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-4">
              <FileText className="h-4 w-4 inline mr-2" />
              Deal Terms
            </h3>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label className="block text-sm text-gray-500">Loan Amount</label>
                <input
                  type="text"
                  value={dealInfo.terms.loanAmount}
                  onChange={(e) => setDealInfo({
                    ...dealInfo,
                    terms: { ...dealInfo.terms, loanAmount: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-500">Interest Rate (%)</label>
                <input
                  type="text"
                  value={dealInfo.terms.interestRate}
                  onChange={(e) => setDealInfo({
                    ...dealInfo,
                    terms: { ...dealInfo.terms, interestRate: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-500">Loan Term (months)</label>
                <input
                  type="text"
                  value={dealInfo.terms.loanTerm}
                  onChange={(e) => setDealInfo({
                    ...dealInfo,
                    terms: { ...dealInfo.terms, loanTerm: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-500">Monthly Payment</label>
                <input
                  type="text"
                  value={dealInfo.terms.monthlyPayment}
                  onChange={(e) => setDealInfo({
                    ...dealInfo,
                    terms: { ...dealInfo.terms, monthlyPayment: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>

          <button
            onClick={handleAnalyze}
            disabled={isLoading}
            className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                Analyzing Deal...
              </>
            ) : (
              <>
                <Wand2 className="-ml-1 mr-2 h-5 w-5" />
                Analyze Deal
              </>
            )}
          </button>
        </div>

        {analysis && (
          <div className="mt-8">
            <h3 className="text-lg font-medium text-gray-900 mb-6">Deal Analysis</h3>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div className="bg-gray-50 rounded-lg p-6">
                <div className="flex items-center justify-between mb-4">
                  <h4 className="font-medium text-gray-900">Profitability</h4>
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    analysis.profitability >= 80 ? 'bg-green-100 text-green-800' :
                    analysis.profitability >= 60 ? 'bg-yellow-100 text-yellow-800' :
                    'bg-red-100 text-red-800'
                  }`}>
                    {analysis.profitability}%
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <h4 className="font-medium text-gray-900">Risk Level</h4>
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    analysis.risk === 'low' ? 'bg-green-100 text-green-800' :
                    analysis.risk === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                    'bg-red-100 text-red-800'
                  }`}>
                    {analysis.risk}
                  </span>
                </div>
              </div>

              <div className="bg-gray-50 rounded-lg p-6">
                <h4 className="font-medium text-gray-900 mb-4">Recommendations</h4>
                <ul className="space-y-2">
                  {analysis.recommendations.map((rec, index) => (
                    <li key={index} className="flex items-start">
                      <span className="flex-shrink-0 h-5 w-5 text-blue-500">•</span>
                      <span className="ml-2 text-sm text-gray-600">{rec}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="sm:col-span-2 bg-gray-50 rounded-lg p-6">
                <h4 className="font-medium text-gray-900 mb-4">Alternative Scenarios</h4>
                <ul className="space-y-2">
                  {analysis.alternatives.map((alt, index) => (
                    <li key={index} className="flex items-start">
                      <span className="flex-shrink-0 h-5 w-5 text-blue-500">•</span>
                      <span className="ml-2 text-sm text-gray-600">{alt}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}