import React from 'react';
import { Car, MapPin, ExternalLink, Tag } from 'lucide-react';
import type { InventoryItem } from '../../types/inventory';
import { formatCurrency } from '../../utils/formatters';

interface InventoryGridProps {
  inventory: InventoryItem[];
}

export function InventoryGrid({ inventory }: InventoryGridProps) {
  if (!inventory?.length) {
    return (
      <div className="text-center py-12">
        <Car className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">No vehicles found</h3>
        <p className="mt-1 text-sm text-gray-500">
          Try adjusting your filters or check back later.
        </p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {inventory.map((vehicle) => (
        <div
          key={vehicle.id}
          className="overflow-hidden rounded-lg bg-white shadow-md hover:shadow-lg transition-shadow duration-300"
        >
          <div className="aspect-w-16 aspect-h-9 relative">
            <img
              src={vehicle.imageUrl || 'https://images.unsplash.com/photo-1611566026373-c6c8da0ea861?auto=format&fit=crop&w=1600&q=80'}
              alt={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
              className="h-48 w-full object-cover"
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = 'https://images.unsplash.com/photo-1611566026373-c6c8da0ea861?auto=format&fit=crop&w=1600&q=80';
              }}
            />
            <div className="absolute top-0 left-0 m-2">
              <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                vehicle.condition === 'New'
                  ? 'bg-blue-100 text-blue-800'
                  : 'bg-yellow-100 text-yellow-800'
              }`}>
                {vehicle.condition}
              </span>
            </div>
            <div className="absolute top-0 right-0 m-2">
              <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                vehicle.status === 'In Stock' 
                  ? 'bg-green-100 text-green-800' 
                  : 'bg-gray-100 text-gray-800'
              }`}>
                {vehicle.status}
              </span>
            </div>
          </div>

          <div className="p-4">
            <div className="mb-2">
              <h3 className="text-lg font-semibold text-gray-900">
                {vehicle.year} {vehicle.make} {vehicle.model}
              </h3>
              <p className="text-sm text-gray-500">{vehicle.trim}</p>
            </div>

            <p className="mt-1 text-sm text-gray-500 line-clamp-2">
              {vehicle.description}
            </p>

            <div className="mt-4 flex items-center justify-between">
              <div className="text-lg font-bold text-gray-900">
                {formatCurrency(vehicle.price)}
              </div>
              <div className="flex items-center text-sm text-gray-500">
                <Car className="mr-1 h-4 w-4" />
                {vehicle.mileage.toLocaleString()} km
              </div>
            </div>

            <div className="mt-4 flex items-center justify-between">
              <div className="flex items-center text-sm text-gray-500">
                <MapPin className="mr-1 h-4 w-4" />
                {vehicle.location.city}, {vehicle.location.province}
              </div>
              <div className="flex items-center text-sm text-gray-500">
                <Tag className="mr-1 h-4 w-4" />
                {vehicle.stockNumber}
              </div>
            </div>

            <div className="mt-4 flex justify-end">
              <a
                href={vehicle.listingUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center text-sm font-medium text-blue-600 hover:text-blue-800"
              >
                View Details
                <ExternalLink className="ml-1 h-4 w-4" />
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}