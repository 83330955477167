import React, { useState, useEffect } from 'react';
import { Plus, Search, Filter } from 'lucide-react';
import { LeadFilters } from '../components/leads/LeadFilters';
import { LeadList } from '../components/leads/LeadList';
import { LeadGrid } from '../components/leads/LeadGrid';
import { NewLeadModal } from '../components/leads/NewLeadModal';
import { SMSComposerModal } from '../components/communications/SMSComposerModal'; 
import { CustomerContactModal } from '../components/customers/CustomerContactModal';
import type { Lead } from '../types/lead';
import { useNotifications } from '../context/NotificationContext';
import { getLeads, updateLead } from '../services/database/leadService';

export function Leads() {
  const { addNotification } = useNotifications();
  const [leads, setLeads] = useState<Lead[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showNewLeadModal, setShowNewLeadModal] = useState(false);
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedSource, setSelectedSource] = useState<string>('all');
  const [selectedStatus, setSelectedStatus] = useState<string>('all');
  const [showFilters, setShowFilters] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedLeadForMessage, setSelectedLeadForMessage] = useState<Lead | null>(null);

  const handleNewLead = async (leadData: Omit<Lead, 'id' | 'createdAt'>) => {
    try {
      const newLead: Lead = {
        id: Math.random().toString(36).substr(2, 9),
        ...leadData,
        createdAt: new Date().toISOString(),
      };
      setLeads(prevLeads => [newLead, ...prevLeads]);
      setShowNewLeadModal(false);
      addNotification({
        type: 'lead',
        title: 'Lead Created',
        message: `New lead ${leadData.name} has been created`,
        priority: 'medium'
      });
    } catch (error) {
      console.error('Error adding lead:', error);
      addNotification({
        type: 'system',
        title: 'Error',
        message: 'Failed to create lead',
        priority: 'high'
      });
    }
  };

  useEffect(() => {
    loadLeads();
  }, []);

  const loadLeads = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await getLeads();
      setLeads(data);
    } catch (err) {
      setError('Failed to load leads. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateLead = async (id: string, data: Partial<Lead>) => {
    try {
      await updateLead(id, data);
      await loadLeads();
      addNotification({
        type: 'lead',
        title: 'Lead Updated',
        message: `Lead status updated to ${data.status}`,
        priority: 'low'
      });
    } catch (error) {
      console.error('Error updating lead:', error);
      addNotification({
        type: 'system',
        title: 'Error',
        message: error instanceof Error ? error.message : 'Failed to update lead status',
        priority: 'high'
      });
    }
  };

  const handleSendEmail = (lead: Lead, message: string) => {
    setSelectedLeadForMessage(lead);
    setShowEmailModal(true);
  };

  const handleSendSMS = (lead: Lead, message: string) => {
    setSelectedLeadForMessage(lead);
    setShowSMSModal(true);
  };

  const filteredLeads = leads.filter(lead => {
    const matchesSearch = !searchQuery || 
      lead.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      lead.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      lead.phone.includes(searchQuery) ||
      lead.vehicle.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesSource = selectedSource === 'all' || lead.source === selectedSource;
    const matchesStatus = selectedStatus === 'all' || lead.status === selectedStatus;

    return matchesSearch && matchesSource && matchesStatus;
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error</h3>
            <div className="mt-2 text-sm text-red-700">
              <p>{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Leads ({filteredLeads.length})</h1>
          <p className="mt-2 text-sm text-gray-700">
            Manage and track your sales leads
          </p>
        </div>
        <button
          onClick={() => setShowNewLeadModal(true)}
          className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
        >
          <Plus className="h-5 w-5" />
          New Lead
        </button>
      </div>

      <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
        <div className="relative flex-1 max-w-md">
          <Search className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search leads..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="block w-full rounded-md border-0 py-2.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
          />
        </div>
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="inline-flex items-center gap-x-2 rounded-md bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <Filter className="h-5 w-5 text-gray-400" />
          Filters
        </button>
      </div>

      {showFilters && (
        <div className="bg-white shadow rounded-lg p-4">
          <LeadFilters
            selectedStatus={selectedStatus}
            selectedSource={selectedSource}
            onStatusChange={setSelectedStatus}
            onSourceChange={setSelectedSource}
          />
        </div>
      )}

      <div className="space-y-4">
        <LeadGrid
          leads={filteredLeads}
          scores={filteredLeads.reduce((acc, lead) => ({ ...acc, [lead.id]: lead.score || 0 }), {})}
          selectedLead={selectedLead?.id || null}
          onSelectLead={(id) => setSelectedLead(leads.find(l => l.id === id) || null)}
          onUpdate={handleUpdateLead}
          onShowScore={() => {}}
          onShowInsights={() => {}}
          onSendEmail={handleSendEmail}
          onSendSMS={handleSendSMS}
        />
      </div>

      {showNewLeadModal && (
        <NewLeadModal
          onClose={() => setShowNewLeadModal(false)}
          onSubmit={handleNewLead}
        />
      )}

      {showEmailModal && selectedLeadForMessage && (
        <CustomerContactModal
          customer={{
            id: selectedLeadForMessage.id,
            firstName: selectedLeadForMessage.name.split(' ')[0],
            lastName: selectedLeadForMessage.name.split(' ')[1] || '',
            email: selectedLeadForMessage.email,
            phone: selectedLeadForMessage.phone,
            address: '',
            purchaseDate: '',
            birthday: '',
            status: selectedLeadForMessage.status,
            preferred_contact: 'email',
            vehicle: {
              make: '',
              model: '',
              year: new Date().getFullYear(),
              vin: ''
            },
            salesRepId: '',
            notes: '',
            lastContact: selectedLeadForMessage.lastContact
          }}
          mode="email"
          onClose={() => {
            setShowEmailModal(false);
            setSelectedLeadForMessage(null);
          }}
        />
      )}

      {showSMSModal && selectedLeadForMessage && (
        <SMSComposerModal
          recipientName={selectedLeadForMessage.name}
          phoneNumber={selectedLeadForMessage.phone}
          onClose={() => {
            setShowSMSModal(false);
            setSelectedLeadForMessage(null);
          }}
        />
      )}
    </div>
  );
}