import { supabase } from '../../lib/supabase';
import type { Customer } from '../../types/customer';
import { sampleCustomers } from '../../data/sampleCustomers';

export async function getCustomers() {
  try {
    const { data, error } = await supabase
      .from('customers')
      .select(`
        *,
        vehicle,
        review_status
      `)
      .order('created_at', { ascending: false });

    if (error) {
      console.warn('Supabase error, using sample data:', error);
      return sampleCustomers;
    }

    return data?.length ? data.map((customer: any) => ({
      id: customer.id,
      firstName: customer.first_name,
      lastName: customer.last_name,
      email: customer.email,
      phone: customer.phone,
      address: customer.address,
      purchaseDate: customer.purchase_date,
      birthday: customer.birthday,
      anniversary: customer.anniversary,
      status: customer.status,
      preferred_contact: customer.preferred_contact,
      vehicle: customer.vehicle,
      salesRepId: customer.sales_rep_id,
      notes: customer.notes,
      lastContact: customer.last_contact,
      reviewStatus: customer.review_status
    })) : sampleCustomers;
  } catch (error) {
    console.error('Error fetching customers:', error);
    return sampleCustomers;
  }
}

export async function createCustomer(customer: Omit<Customer, 'id'>) {
  try {
    const { data, error } = await supabase
      .from('customers')
      .insert([{
        first_name: customer.firstName,
        last_name: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        address: customer.address,
        purchase_date: customer.purchaseDate,
        birthday: customer.birthday,
        anniversary: customer.anniversary,
        status: customer.status,
        preferred_contact: customer.preferred_contact,
        vehicle: customer.vehicle,
        sales_rep_id: customer.salesRepId,
        notes: customer.notes,
        last_contact: customer.lastContact,
        review_status: customer.reviewStatus
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating customer:', error);
    throw error;
  }
}

export async function updateCustomer(id: string, customer: Partial<Customer>) {
  try {
    const { error } = await supabase
      .from('customers')
      .update({
        first_name: customer.firstName,
        last_name: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        address: customer.address,
        purchase_date: customer.purchaseDate,
        birthday: customer.birthday,
        anniversary: customer.anniversary,
        status: customer.status,
        preferred_contact: customer.preferred_contact,
        vehicle: customer.vehicle,
        sales_rep_id: customer.salesRepId,
        notes: customer.notes,
        last_contact: customer.lastContact,
        review_status: customer.reviewStatus,
        updated_at: new Date().toISOString()
      })
      .eq('id', id);

    if (error) throw error;
  } catch (error) {
    console.error('Error updating customer:', error);
    throw error;
  }
}

export async function deleteCustomer(id: string) {
  try {
    const { error } = await supabase
      .from('customers')
      .delete()
      .eq('id', id);

    if (error) throw error;
  } catch (error) {
    console.error('Error deleting customer:', error);
    throw error;
  }
}