import React, { useState, useEffect } from 'react';
import { Plus, Filter } from 'lucide-react';
import { SmartListBuilder } from '../components/smartLists/SmartListBuilder';
import { SmartListCard } from '../components/smartLists/SmartListCard';
import { SmartListMembers } from '../components/smartLists/SmartListMembers';
import { PowerDialer } from '../components/dialer/PowerDialer';
import { getSmartLists, createSmartList, updateSmartList, deleteSmartList } from '../services/smartListService';
import type { SmartList } from '../types/smartList';
import type { Lead } from '../types/lead';

export function SmartLists() {
  const [lists, setLists] = useState<SmartList[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showBuilder, setShowBuilder] = useState(false);
  const [editingList, setEditingList] = useState<SmartList | null>(null);
  const [selectedList, setSelectedList] = useState<string | null>(null);
  const [showDialer, setShowDialer] = useState(false);

  useEffect(() => {
    loadLists();
  }, []);

  const loadLists = async () => {
    try {
      setLoading(true);
      const data = await getSmartLists();
      setLists(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load smart lists');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateList = async (list: Omit<SmartList, 'id' | 'createdAt' | 'updatedAt' | 'memberCount'>) => {
    try {
      await createSmartList(list);
      await loadLists();
      setShowBuilder(false);
    } catch (err) {
      console.error('Error creating list:', err);
      throw err;
    }
  };

  const handleUpdateList = async (list: SmartList) => {
    try {
      await updateSmartList(list.id, list);
      await loadLists();
      setEditingList(null);
    } catch (err) {
      console.error('Error updating list:', err);
      throw err;
    }
  };

  const handleDeleteList = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this list?')) return;
    
    try {
      await deleteSmartList(id);
      await loadLists();
      if (selectedList === id) {
        setSelectedList(null);
      }
    } catch (err) {
      console.error('Error deleting list:', err);
    }
  };

  const handleSendSMS = (lead: Lead) => {
    // TODO: Implement SMS sending
    console.log('Send SMS to:', lead);
  };

  const handleSendEmail = (lead: Lead) => {
    // TODO: Implement email sending
    console.log('Send email to:', lead);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Smart Lists</h1>
          <div className="mt-2 flex items-center gap-4">
            <p className="text-sm text-gray-700">
              Create dynamic lists based on lead properties and activity
            </p>
            {selectedList && (
              <button
                onClick={() => setShowDialer(true)}
                className="inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500"
              >
                <Phone className="h-4 w-4 mr-1.5" />
                Start Power Dialer
              </button>
            )}
          </div>
        </div>
        <button
          onClick={() => setShowBuilder(true)}
          className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
        >
          <Plus className="-ml-0.5 h-5 w-5" />
          New List
        </button>
      </div>

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Error</h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{error}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
        {lists.map((list) => (
          <SmartListCard
            key={list.id}
            list={list}
            onEdit={() => setEditingList(list)}
            onDelete={() => handleDeleteList(list.id)}
            onToggleActive={() => setSelectedList(selectedList === list.id ? null : list.id)}
            isActive={selectedList === list.id}
          />
        ))}

        {lists.length === 0 && !showBuilder && (
          <div className="lg:col-span-2">
            <div className="text-center">
              <Filter className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">No smart lists</h3>
              <p className="mt-1 text-sm text-gray-500">
                Get started by creating a new smart list
              </p>
              <div className="mt-6">
                <button
                  onClick={() => setShowBuilder(true)}
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                >
                  <Plus className="-ml-1 mr-2 h-5 w-5" />
                  New List
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {selectedList && (
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">List Members</h2>
          <SmartListMembers
            listId={selectedList}
            onSendSMS={handleSendSMS}
            onSendEmail={handleSendEmail}
          />
        </div>
      )}

      {(showBuilder || editingList) && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    onClick={() => {
                      setShowBuilder(false);
                      setEditingList(null);
                    }}
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                  >
                    <X className="h-6 w-6" />
                  </button>
                </div>

                <div className="sm:flex sm:items-start">
                  <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                    <h3 className="text-lg font-semibold leading-6 text-gray-900">
                      {editingList ? 'Edit Smart List' : 'New Smart List'}
                    </h3>
                    <div className="mt-6">
                      <SmartListBuilder
                        initialData={editingList || undefined}
                        onSave={editingList ? handleUpdateList : handleCreateList}
                        onCancel={() => {
                          setShowBuilder(false);
                          setEditingList(null);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showDialer && selectedList && (
        <PowerDialer
          listId={selectedList}
          onClose={() => setShowDialer(false)}
        />
      )}
    </div>
  );
}