import React, { useEffect } from 'react';
import { useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { 
  Bot, Car, Brain, Mail, TrendingUp, Target, 
  DollarSign, BarChart2, Users, Wrench, Languages,
  MessageSquare, Calendar, FileText, Presentation,
  Gauge, Award, BookOpen, Lightbulb, Search,
  Star
} from 'lucide-react';
import { motion } from 'framer-motion';

// Group tools by category
const toolCategories = [
  {
    name: 'Sales Tools',
    icon: Brain, 
    tools: [
      {
        name: 'Sales Coach',
        href: '/ai-tools/sales-coach',
        icon: Brain,
        description: 'Get AI-powered sales coaching and advice'
      },
      {
        name: 'Objection Handler',
        href: '/ai-tools/objection-handler',
        icon: Target,
        description: 'AI responses to common objections'
      },
      {
        name: 'Call Scripts',
        href: '/ai-tools/call-scripts',
        icon: MessageSquare,
        description: 'Generate effective call scripts'
      },
      {
        name: 'Chat Scripts',
        href: '/ai-tools/chat-scripts',
        icon: MessageSquare,
        description: 'Generate engaging chat scripts'
      },
      {
        name: 'Follow-up Planner',
        href: '/ai-tools/follow-up',
        icon: Calendar,
        description: 'AI-optimized follow-up schedules'
      }
    ]
  },
  {
    name: 'Vehicle Tools',
    icon: Car,
    tools: [
      {
        name: 'Vehicle Recommender',
        href: '/ai-tools/recommender',
        icon: Car,
        description: 'AI-powered vehicle recommendations'
      },
      {
        name: 'Price Optimizer',
        href: '/ai-tools/price-optimizer',
        icon: DollarSign,
        description: 'Optimize pricing strategies'
      },
      {
        name: 'Inventory Optimizer',
        href: '/ai-tools/inventory-optimizer',
        icon: BarChart2,
        description: 'Optimize inventory levels'
      },
      {
        name: 'Deal Analyzer',
        href: '/ai-tools/deal-analyzer',
        icon: Award,
        description: 'Analyze and optimize deals'
      }
    ]
  },
  {
    name: 'Marketing Tools',
    icon: TrendingUp,
    tools: [
      {
        name: 'Market Analyzer',
        href: '/ai-tools/market-analyzer',
        icon: TrendingUp,
        description: 'Market trends and analysis'
      },
      {
        name: 'Competitor Analysis',
        href: '/ai-tools/competitor-analysis',
        icon: Target,
        description: 'Analyze competitor strategies'
      },
      {
        name: 'Email Generator',
        href: '/ai-tools/email',
        icon: Mail,
        description: 'Generate personalized emails'
      }
    ]
  },
  {
    name: 'Customer Tools',
    icon: Users,
    tools: [
      {
        name: 'Customer Insights',
        href: '/ai-tools/insights',
        icon: Users,
        description: 'Deep customer behavior analysis'
      },
      {
        name: 'Customer Segmentation',
        href: '/ai-tools/customer-segmentation',
        icon: Users,
        description: 'AI-powered customer segmentation'
      },
      {
        name: 'Service Predictor',
        href: '/ai-tools/service-predictor',
        icon: Wrench,
        description: 'Predict service needs'
      }
    ]
  },
  {
    name: 'Content Tools',
    icon: FileText,
    tools: [
      {
        name: 'Document Generator',
        href: '/ai-tools/documents',
        icon: FileText,
        description: 'Create professional documents'
      },
      {
        name: 'Presentation Builder',
        href: '/ai-tools/presentations',
        icon: Presentation,
        description: 'Build compelling presentations'
      }
    ]
  },
  {
    name: 'Training & Development',
    icon: BookOpen,
    tools: [
      {
        name: 'Performance Coach',
        href: '/ai-tools/performance',
        icon: Gauge,
        description: 'Personal performance coaching'
      },
      {
        name: 'Training Modules',
        href: '/ai-tools/training',
        icon: BookOpen,
        description: 'Interactive training sessions'
      },
      {
        name: 'Innovation Lab',
        href: '/ai-tools/innovation',
        icon: Lightbulb,
        description: 'Experiment with new features'
      }
    ]
  }
];

export function AITools() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  useEffect(() => {
    if (location.pathname === '/ai-tools') {
      navigate('/ai-tools/sales-coach');
    }
  }, [location, navigate]);

  const filteredTools = toolCategories.flatMap(category => 
    category.tools.filter(tool =>
      tool.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      tool.description.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div className="space-y-6">
      <div className="sm:flex sm:items-center sm:justify-between">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <h1 className="text-2xl font-semibold text-gray-900">AI Tools</h1>
          <p className="mt-2 text-sm text-gray-700">
            Leverage AI to enhance your sales and customer service
          </p>
        </motion.div>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.1 }}
          className="relative mt-4 sm:mt-0"
        >
          <Search className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search tools..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-10 w-full sm:w-64 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </motion.div>
      </div>

      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        className="flex space-x-2 overflow-x-auto pb-2"
      >
        <button
          onClick={() => setSelectedCategory(null)}
          className={`px-4 py-2 rounded-full text-sm font-medium whitespace-nowrap ${
            !selectedCategory
              ? 'bg-blue-100 text-blue-800'
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
        >
          All Tools
        </button>
        {toolCategories.map((category) => (
          <button
            key={category.name}
            onClick={() => setSelectedCategory(category.name)}
            className={`px-4 py-2 rounded-full text-sm font-medium whitespace-nowrap ${
              selectedCategory === category.name
                ? 'bg-blue-100 text-blue-800'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            <category.icon className="inline-block h-4 w-4 mr-1.5 -mt-0.5" />
            {category.name}
          </button>
        ))}
      </motion.div>

      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.3 }}
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
      >
        {(searchQuery || selectedCategory ? filteredTools : toolCategories.flatMap(c => c.tools))
          .filter(tool => !selectedCategory || toolCategories.find(c => c.name === selectedCategory)?.tools.includes(tool))
          .map((tool) => (
            <motion.button
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              key={tool.name}
              onClick={() => navigate(tool.href)}
              className="group relative block w-full rounded-lg bg-white p-6 shadow-sm ring-1 ring-gray-200 hover:ring-blue-500 hover:shadow-md transition-all duration-200"
            >
              <div className="flex items-center justify-between mb-4">
                <tool.icon className="h-8 w-8 text-blue-500 group-hover:text-blue-600 transition-colors" />
                <div className="opacity-0 group-hover:opacity-100 transition-opacity">
                  <span className="text-xs font-medium text-blue-600">Open Tool →</span>
                </div>
              </div>
              <h3 className="text-lg font-semibold text-gray-900 group-hover:text-blue-600 transition-colors">
                {tool.name}
              </h3>
              <p className="mt-2 text-sm text-gray-500 group-hover:text-gray-600">
                {tool.description}
              </p>
            </motion.button>
          ))}
      </motion.div>

      <Outlet />
    </div>
  );
}