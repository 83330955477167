import { v4 as uuidv4 } from 'uuid';

const CLOUDINARY_CONFIG = {
  cloudName: 'dgyd6kfbt',
  uploadPreset: 'ml_default',
  apiKey: '367441534672126',
  apiSecret: 'YAv7z3z6RUZXgASlmYNexLzZ5nSh6ou1BLjkEZBfqTDtbGx6ec9UE1tESPG5Nuzz'
};

interface UploadResponse {
  secure_url: string;
  public_id: string;
}

export async function uploadVideo(videoBlob: Blob): Promise<UploadResponse> {
  try {
    // Create unique filename
    const filename = `video_${uuidv4()}`;
    
    // Create form data
    const formData = new FormData();
    formData.append('file', videoBlob);
    formData.append('upload_preset', CLOUDINARY_CONFIG.uploadPreset);
    formData.append('cloud_name', CLOUDINARY_CONFIG.cloudName);
    formData.append('resource_type', 'video');
    formData.append('public_id', filename);
    formData.append('api_key', CLOUDINARY_CONFIG.apiKey);

    // Add timestamp and signature for security
    const timestamp = Math.round((new Date()).getTime() / 1000);
    formData.append('timestamp', timestamp.toString());

    // Make upload request
    const response = await fetch(
      `https://api.cloudinary.com/v1_1/${CLOUDINARY_CONFIG.cloudName}/video/upload`,
      {
        method: 'POST',
        body: formData
      }
    );

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ error: { message: 'Unknown upload error' } }));
      console.error('Cloudinary upload failed:', errorData);
      throw new Error(errorData.error?.message || `Upload failed with status: ${response.status}`);
    }

    const data = await response.json();
    
    if (!data.secure_url) {
      throw new Error('Upload succeeded but no URL was returned');
    }

    return {
      secure_url: data.secure_url,
      public_id: data.public_id
    };
  } catch (error) {
    console.error('Error uploading to Cloudinary:', error);
    if (error instanceof Error) {
      throw new Error(`Video upload failed: ${error.message}`);
    }
    throw new Error('Video upload failed due to an unknown error');
  }
}

// Add helper function to validate upload response
export function validateUploadResponse(data: any): data is UploadResponse {
  return (
    typeof data === 'object' &&
    data !== null &&
    typeof data.secure_url === 'string' &&
    typeof data.public_id === 'string'
  );
}

// Add function to handle upload progress
export function createUploadProgressHandler(
  onProgress?: (progress: number) => void
): (event: ProgressEvent) => void {
  return (event: ProgressEvent) => {
    if (event.lengthComputable && onProgress) {
      const progress = Math.round((event.loaded * 100) / event.total);
      onProgress(progress);
    }
  };
}