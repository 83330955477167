import React, { useState } from 'react';
import { Gauge, Wand2, Loader2, TrendingUp, Target, Award } from 'lucide-react';
import { useAI } from '../components/ai/AIContext';

interface PerformanceMetrics {
  leads: number;
  conversions: number;
  revenue: number;
  avgResponseTime: number;
  customerSatisfaction: number;
}

export function PerformanceCoach() {
  const { generateResponse, isLoading } = useAI();
  const [metrics, setMetrics] = useState<PerformanceMetrics>({
    leads: 0,
    conversions: 0,
    revenue: 0,
    avgResponseTime: 0,
    customerSatisfaction: 0
  });
  const [goals, setGoals] = useState({
    leadTarget: '',
    conversionTarget: '',
    revenueTarget: '',
    focusAreas: [] as string[]
  });
  const [analysis, setAnalysis] = useState<string | null>(null);

  const handleAnalyze = async () => {
    const prompt = `Analyze sales performance and provide coaching based on:
      Current Performance:
      - Leads: ${metrics.leads}
      - Conversions: ${metrics.conversions}
      - Revenue: $${metrics.revenue.toLocaleString()}
      - Avg Response Time: ${metrics.avgResponseTime} hours
      - Customer Satisfaction: ${metrics.customerSatisfaction}/10

      Goals:
      - Lead Target: ${goals.leadTarget}
      - Conversion Target: ${goals.conversionTarget}%
      - Revenue Target: $${goals.revenueTarget}
      - Focus Areas: ${goals.focusAreas.join(', ')}

      Provide:
      1. Performance analysis
      2. Specific improvement recommendations
      3. Action steps for each focus area
      4. Key metrics to track
      5. Success strategies`;

    const response = await generateResponse(prompt);
    if (response) {
      setAnalysis(response);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center gap-2 mb-6">
          <Gauge className="h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-semibold text-gray-900">Performance Coach</h2>
        </div>

        <div className="space-y-6">
          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-4">Current Performance</h3>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
              <div className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <label className="text-sm text-gray-500">Leads</label>
                  <Target className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  value={metrics.leads}
                  onChange={(e) => setMetrics({ ...metrics, leads: parseInt(e.target.value) || 0 })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <label className="text-sm text-gray-500">Conversions</label>
                  <TrendingUp className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  value={metrics.conversions}
                  onChange={(e) => setMetrics({ ...metrics, conversions: parseInt(e.target.value) || 0 })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <label className="text-sm text-gray-500">Revenue</label>
                  <Award className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  value={metrics.revenue}
                  onChange={(e) => setMetrics({ ...metrics, revenue: parseInt(e.target.value) || 0 })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <label className="text-sm text-gray-500">Avg Response Time (hours)</label>
                  <Clock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  value={metrics.avgResponseTime}
                  onChange={(e) => setMetrics({ ...metrics, avgResponseTime: parseFloat(e.target.value) || 0 })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <label className="text-sm text-gray-500">Customer Satisfaction (1-10)</label>
                  <Star className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  min="1"
                  max="10"
                  value={metrics.customerSatisfaction}
                  onChange={(e) => setMetrics({ ...metrics, customerSatisfaction: parseInt(e.target.value) || 0 })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-4">Performance Goals</h3>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
              <div>
                <label className="block text-sm text-gray-500">Lead Target</label>
                <input
                  type="text"
                  value={goals.leadTarget}
                  onChange={(e) => setGoals({ ...goals, leadTarget: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="e.g. 50"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-500">Conversion Target (%)</label>
                <input
                  type="text"
                  value={goals.conversionTarget}
                  onChange={(e) => setGoals({ ...goals, conversionTarget: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="e.g. 25"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-500">Revenue Target</label>
                <input
                  type="text"
                  value={goals.revenueTarget}
                  onChange={(e) => setGoals({ ...goals, revenueTarget: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="e.g. 250000"
                />
              </div>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Focus Areas</label>
            <select
              multiple
              value={goals.focusAreas}
              onChange={(e) => setGoals({
                ...goals,
                focusAreas: Array.from(e.target.selectedOptions, option => option.value)
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              size={5}
            >
              <option value="lead_generation">Lead Generation</option>
              <option value="closing_skills">Closing Skills</option>
              <option value="product_knowledge">Product Knowledge</option>
              <option value="negotiation">Negotiation</option>
              <option value="follow_up">Follow-up Process</option>
              <option value="time_management">Time Management</option>
              <option value="customer_service">Customer Service</option>
              <option value="digital_sales">Digital Sales</option>
            </select>
          </div>

          <button
            onClick={handleAnalyze}
            disabled={isLoading}
            className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
                Analyzing Performance...
              </>
            ) : (
              <>
                <Wand2 className="-ml-1 mr-2 h-5 w-5" />
                Generate Analysis
              </>
            )}
          </button>
        </div>

        {analysis && (
          <div className="mt-8">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Performance Analysis</h3>
            <div className="bg-gray-50 rounded-lg p-6">
              <div className="prose prose-blue max-w-none">
                {analysis.split('\n').map((line, index) => (
                  <p key={index} className="text-gray-600">{line}</p>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}